<template>
  <div id="slider">
    <!-- img-container -->
    <div
      class="slider-imgcontainer"
      style="display: flex;"
      ref="container"
    >
      <!-- <img  v-for="(img,index) in imgs" :key="index" :src="img.url" alt="img.index"> -->

      <div v-for="(img,index) in imgs" :key="index" alt="img.index" class="img"> 
        <div>
          {{img.name}}

        </div>
        <div>
          检查类型：<span>{{img.type}}</span>
        </div>
        <div>
          检 查 人：{{img.person_names}}
        </div>
        <div style="display:flex; text-align:left;">
          <div style="width: 35%;height: 100%;display: flex;justify-content: flex-start;text-align:left;">
            检查结果：

          </div>
          
          {{img.resultok}}
        </div>
        <div style="display:flex;">
          <div> 检查意见：</div>
         <div>{{img.result}}</div>
        </div>
        <div style="    display: flex;align-items: flex-start;">
          检查图片：<img :src="img.thumbs" alt="" style="width: 70px;height: 70px;">
        </div>
        <div>
          检查时间：{{img.datetime}}
        </div>

      </div>
    </div>
    <!-- index -->

    <!-- left right button -->
    <div class="slider-move" style="display:none">
      <span class="to-left" @click="doTheAnimate('left')"><i class="iconfont">&#xe637;</i></span>
      <span class="to-right" @click="doTheAnimate('right')"><i class="iconfont">&#xe70a;</i></span>
    </div>
  </div>
</template>
 
<script>
  export default {
    name: 'SliderIndex',
    data () {
      // /**
      //  * @property {array} imgs 图片组
      //  * @property {number} currentIndex 当前图片的索引
      //  * @property {array} buttons 索引按钮
      //  * @property {number} timer 计时器
      //  */
      return{
        imgs:[
          {url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg2.niutuku.com%2Fdesk%2F1207%2F0839%2Fntk32820.jpg&refer=http%3A%2F%2Fimg2.niutuku.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652511531&t=d64c017848f007fbb917d0cf68d64fb1', index: 0},
          {url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-091300%2Ffoopichqjzafoopichqjza.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652511531&t=755a2fdecdfab5c0b4f2fc472658ebad', index: 1},
          {url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-091106%2F40r5dn0n3zm40r5dn0n3zm.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652511531&t=d3abe7eedb79b20a992c251d2cadcd1a', index: 2},
          {url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-091102%2Fdo3ksc0ynaido3ksc0ynai.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1652511531&t=2a2e36fac6e97461247856f1f1f68f13', index: 3}],
        currentIndex: 0,
        buttons: [0,1,2,3],
        timer: null
      }
    },
    methods: {



    /**
     * @method
     * @param {string} left 图片容器的left值
     * @desc 移动图片容器
     */
      move() {
        let left = `-${this.currentIndex * 100}%`
        this.$refs.container.style.left = left;
      },
      /**
       * @method
       * @param {number,string} arg 由Dom传递过来的参数，
       * 鼠标悬停事件传递的是number类型；鼠标点击事件传递的是string类型：'left' or 'right'
       * @desc 调整currentIndex的值，鼠标点击或是悬停都清空计时器，避免发生冲突
       */
      doTheAnimate(arg) {
        clearInterval(this.timer);
        this.timer = null;
        // 鼠标悬停
        if( typeof(arg) == 'number' ) {
          this.currentIndex = arg;
          // 鼠标点击
        }else if(typeof(arg) == 'string') {
          if(arg && arg === 'left' && this.currentIndex <= 2){
            this.currentIndex ++;
          }else if(arg && arg === 'right' && this.currentIndex >= 1){
            this.currentIndex --;
          }
          if(!this.timer) {
            this.autoAnimate();
          }
        }
        this.move();
      },
      /**
       * @method
       * @desc 自动轮播图片
       */
      autoAnimate() {
        this.timer = setInterval(() => {
          if(this.currentIndex === 19){
            this.currentIndex = 0;
          }else {
            this.currentIndex ++ ;
          }
          this.move();
        },4000)
      },

      handlesj(){
         this.$http.get(ppppl+'/jee5/verson/inspect/query')
        .then( (res) => {
           console.log(res.data.result)
           this.imgs=res.data.result

         })
      }
    },
    mounted () {
      this.autoAnimate();
      this.handlesj()
    }
  }
</script>
 
<style>
  *{
    margin: 0;
    padding: 0;
  }
  @font-face {
    font-family: 'iconfont';  /* project id 847125 */
    src: url('//at.alicdn.com/t/font_847125_qhbkbwtxr2.eot');
    src: url('//at.alicdn.com/t/font_847125_qhbkbwtxr2.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_847125_qhbkbwtxr2.woff') format('woff'),
    url('//at.alicdn.com/t/font_847125_qhbkbwtxr2.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_847125_qhbkbwtxr2.svg#iconfont') format('svg');
  }
  .iconfont {
    font-family:"iconfont";
    font-size:16px;
    font-style:normal;
  }
  #slider{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
  }
  .slider-imgcontainer {
    position: absolute;
    left: 0;
    width: 2000%;
    height: 100%;
    transition: left .5s linear;
  }
  .img {
    width: 21%;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: #ffffff;
    font-size: 14px;
  }
  .slider-buttons {
    position: absolute;
    width: 30%;
    left: 35%;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .slider-imgindex {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin: 0 10px;
    background-color: rgba(255, 248, 248, 0.98);
    border-radius: 7px;
    transition: width .3s linear;
  }
  .slider-imgindex-active {
    width: 60px;
  }
  .slider-move {
    position: absolute;
    top: 40%;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .slider-move span {
    cursor: pointer;
    text-align: center;
    color: #fff;
    line-height: 60px;
    width: 50px;
    background-color: rgba(8,1,1,0.62);
  }
  .slider-move span:hover{
    background-color: rgba(8,1,1,0.9);
  }
</style>