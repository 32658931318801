<template>
  <div
    id="map1"
    style="
      width: 70%;
      height: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    "
  ></div>
  
</template>

<script>
import china1 from "../common/map/340121.json";
export default {
  name: "map",
  data() {
    return {
      // option 也可以在这里定义
    };
  },
  mounted() {



    var allData = [
    { name: '济南市', value: 396 },
    { name: '青岛市', value: 66 },
    { name: '烟台市', value: 222 },
    { name: '潍坊市', value: 688 },
    { name: '菏泽市', value: 75 },
    { name: '日照市', value: 121 },
    { name: '威海市', value: 91 },
    { name: '枣庄市', value: 479 },
    { name: '临沂市', value: 34 },
    { name: '滨州市', value: 631 },
    { name: '东营市', value: 1203 },
    { name: '淄博市', value: 988 },
    { name: '泰安市', value: 693 },
    { name: '聊城市', value: 934 },
    { name: '济宁市', value: 748 },

];

 console.log(allData)

    
    this.$echarts.registerMap("china1", china1);
    let myCharts = this.$echarts.init(document.getElementById("map1"));
    var option = {
      geo: {
        type: "map1",
        map: "china1",
        roam: false,
        //  center: [105, 36],
        // layoutCenter: ['70%', '50%'],//位置
        zoom: 1.2,
        label: {
          normal: {
            show: true,
            textStyle: {
              color: "#ffffff",
              fontSize:'20'
            },
          },
          emphasis: {
            show: true,
            textStyle: {
              color: "#ffffff",
            },
          },
        },
        itemStyle: {
          normal: {
                areaColor: '#263E78',
                shadowColor:'rgba(128, 217, 248, 0.2)',
                shadowOffsetX: 5,
                shadowOffsetY: 0
              },
          emphasis: {
            areaColor: "#1FDBE0",
          },
        },
      },
      series: [
        // 这里是地区颜色
        {
            name: '地区',
            type: 'map',
            geoIndex: 0,
            data: [
                {
                    name: '水湖镇',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",

                },
                {
                    name: '左店乡',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",
                },
                {
                    name: '罗塘乡',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",

                },
                {
                    name: '杜集乡',
                    value: 13955188430,
                    valuel: 1231231,
                    namell:"杜靖",
                    namelll:"邓光月",
                    datas:13865159331,
                    img:"/image/djj.png",
                    imgl:"/image/dgy.png",
                },
                {
                    name: '庄墓镇',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",

                },
                {
                    name: '朱巷镇',
                    value: 13965050491,
                    valuel: 1231231,
                    namell:"彭红星",
                    namelll:"张书虎",
                    datas:18122795346,
                    img:"/image/phx.png",
                    imgl:"/image/zsh.png",
                },
                {
                    name: '义井乡',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/baoyong.png",
                    imgl:"/image/dongwei.png",

                },
                {
                    name: '下塘镇',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",
                },
                {
                    name: '造甲乡',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",
                },
                {
                    name: '杨庙镇',
                    value: 13805605407,
                    valuel: 1231231,
                    namell:"郑好贤",
                    namelll:"郑红梅",
                    datas:13966660336,
                    img:"/image/zhx.png",
                    imgl:"/image/zhm.png",
                },
                {
                    name: '陶楼乡',
                    value: 13866777012,
                    valuel: 1231231,
                    namell:"郑聂松",
                    namelll:"陶慧敏",
                    datas:18255188493 ,
                    img:"/image/zns.png",
                    imgl:"/image/thm.png",
                },
                {
                    name: '双墩镇',
                    value: 13695694799,
                    valuel: 1231231,
                    namell:"董传发",
                    namelll:"刘政红",
                    datas:13866718578,
                    img:"/image/dcf.png",
                    imgl:"/image/lzh.png",
                },
                {
                    name: '吴山镇',
                    value: 13955111168,
                    valuel: 1231231,
                    namell:"鲍勇",
                    namelll:"董巍",
                    datas:15156092216,
                    img:"/image/touxiang.png",
                    imgl:"/image/touxiang.png",
                },
                {
                    name: '岗集镇',
                    value: 13956021780,
                    valuel: 1231231,
                    namell:"孙立坤",
                    namelll:"夏登华",
                    datas:13865968193,
                    img:"/image/slk.png",
                    imgl:"/image/xdh.png",
                },
                
            ],
        },

    ],

      tooltip: {
        show:true,
        showContent: true, // 是否显示提示框浮层，默认显示
  

        formatter: (params,data) => {
          console.log(params.data)
          console.log(data)
         

            return (
              "<div style='width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;'><div style='width: 90%;height: 20%;'>"+params.name+"</div><div style='width: 100%;height: 40%;display: flex;margin-top: 5px;'><img src='"+params.data.img+"' alt='' style='width: 30%;height: 100%;'><div style='width: 70%;color: #03bfe6; display: flex;flex-direction: column;justify-content: center;align-items: flex-start;font-size: 12px;line-height: 8px;margin-left: 5px;padding-left: 5px;margin-right: 5px;background: url(/image/bjbj.png);background-repeat: no-repeat;background-size: 100% 100%;'><span>姓名："+params.data.namell+"</span></br><span>联系电话："+params.data.value+"</span></br><span>监管主体：32</span></div></div><div style='width: 100%;height: 50%;display: flex;margin-top: 5px;'><img src='"+params.data.imgl+"' alt='' style='width: 30%;height: 90%;'><div style='width: 70%;color: #03bfe6; display: flex;flex-direction: column;justify-content: center;align-items: flex-start;font-size: 12px;line-height:8px;margin-left: 5px;padding-left: 5px;margin-right: 5px;background: url(/image/bjbj.png);background-repeat: no-repeat;background-size: 100% 100%;'><span>姓名："+params.data.namelll+"</span></br><span>联系电话："+params.data.datas+"</span></br><span>监管主体：32</span></div><div></div>"
            );
            

            
        },
        extraCssText:
          "background:url('/image/tanchuang.png');background-repeat: no-repeat;background-size: 100% 100%;width:210px;height:180px;color:#ffffff;",
      },
      data: allData,
    };
    





    myCharts.setOption(option);
    
  },
};
</script>
<style scoped>
</style>

