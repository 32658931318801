<template>
  <div class="m-select-wrap">
    <input
      :class="['u-select-input f16', color === 'blue' ? '' : 'white-color']"
      type="text"
      readonly
      @click="openSelect"
      @blur="onBlur"
      v-model="selectName"
    />
    <div
      :class="['triangle-down', { rotate: rotate }]"
      @click="openSelect"
    ></div>
    <div
      :class="['m-options-panel f16', showOptions ? 'show' : 'hidden']"
      :style="`height: ${selectData.length * 40}px;`"
    >
      <p
        class="u-option"
        @mousedown="getValue(item.name, item.value, index)"
        v-for="(item, index) in selectData"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Select",
  props: {
    selectData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // eslint-disable-next-line vue/require-prop-types
    selValue: {
      // 将该prop值作为selV的初始值
      default: undefined,
    },
    color: {
      type: String,
      default: () => {
        return "blue";
      },
    },
  },
  computed: {
    selectName() {
      for (const item of this.selectData) {
        if (item.value === this.selectValue) {
          return item.name;
        }
      }
      return "";
    },
  },
  data() {
    return {
      selectValue: this.selValue,
      rotate: false,
      showOptions: false,
    };
  },
  methods: {
    openSelect() {
      this.showOptions = !this.showOptions;
      this.rotate = !this.rotate;
    },
    getValue(name, value, index) {
      this.selectValue = value;
      this.$emit("getValue", name, value, index);
    },
    onBlur() {
      this.showOptions = false;
      this.rotate = false;
    },
  },
};
</script>
<style >
.m-select-wrap {
  width: 135px;
  height: 40px;
  line-height: 40px;
  position: relative;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 9999;
}
.u-select-input {
    width: 75px;
    background: #3a79ee;
    color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgb(144 119 222 / 20%);
    border-radius: 12px;
    border: 1px solid #01B3DE!important;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    cursor: pointer;
    border: none;
}
.white-color {
  /* background: #ffffff; */
  background: linear-gradient(#031e69 0%, #024b96 100%);
  color: #ffffff;
}
.triangle-down {

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  top: 18px;
  right: 28px;
  transition: transform 0.3s ease-in-out;
}
.rotate {
  transform: rotate(180deg);
}
.m-options-panel {
        position: absolute;
    background: #ffffff;
    border-radius: 8px;
    width: 218px;
    border: 1px solid #e3e3e3;
    top: 46px;
    left: -38px;
    color: #706f94;
    font-size: 12px;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.u-option {
  padding: 0 15px;
  cursor: pointer;
}
.u-option:hover {
  color: #3a79ee;
  background: #eef1fa;
  border: 1px solid #01B3DE!important;
   border-radius: 12px;
  
}
</style>