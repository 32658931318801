<template>
  <div class="home">
    <div class="center">
      <div class="center_left">
        <div class="center_left_1 cen_bj1">
          <div
            style="
              width: 100%;
              height: 14%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            长丰草莓简介
          </div>
          <div class="cen_tit">
            <div class="cen_tit_on">
              针对长丰草莓发展现状和保障消费安全的现实需求，在良好
              的草莓产业发展态势的基础上，以促进草莓产业化高效发展和提
              升安全水平为核心，整合和应用现有的资源基础，立足长丰县草
              莓质量安全监管的信息化需求和生产经营流通管理的发展特点，
              面向草莓追溯、投入品追溯等多个出发点，以适应未来的发展需
              求要为建设应用信息系统的基本原则，集成应用现代信息技术，
              <span style="color: red"
                >建立健全长丰草莓质量安全长效监管模式。</span
              >
            </div>
          </div>
        </div>
        <div class="center_left_2 cen_bj2">
          <div
            style="
              width: 100%;
              height: 12%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            监督检查
          </div>

          <div
            style="
              width: 90%;
              height: 80%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              font-size: 16px;
            "
          >
            <div class="chart" style="height: 80%">
              <div id="bar_chart1" class="chart"></div>
              执法次数（次）
            </div>
            <div class="chart" style="height: 80%">
              <div id="bar_chart2" class="chart"></div>
              合格率（%）
            </div>
            <div class="chart" style="height: 80%">
              <div id="bar_chart3" class="chart"></div>
              案件查处次数（次）
            </div>

            <!-- <div id="bar_chart2" class="chart"></div>
            <div id="bar_chart3" class="chart"></div> -->
          </div>
        </div>
        <div class="center_left_3 cen_bj3">
          <div
            style="
              width: 100%;
              height: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            产品认证
          </div>
          <div class="center_left_3xia">
            <div
              style="
                width: 90%;
                height: 20%;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 30%; height: 100%">
                <img
                  src="../../../public/image/tupianlv.png"
                  alt=""
                  style="height: 60px; width: 60px"
                />
              </div>
              <div style="width: 30%; height: 100%" class="juz">
                认证数
                <span style="color: #00f8fa; margin-left: 10px">2778</span>
              </div>
              <div style="width: 30%; height: 100%" class="juz">
                续展率
                <span style="color: #00f8fa; margin-left: 10px">27.28%</span>
              </div>
            </div>
            <div
              style="
                width: 90%;
                height: 20%;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 30%; height: 100%">
                <img
                  src="../../../public/image/tupioanlv2.png"
                  alt=""
                  style="height: 60px; width: 60px"
                />
              </div>
              <div style="width: 30%; height: 100%" class="juz">
                认证数
                <span style="color: #00f8fa; margin-left: 10px">2778</span>
              </div>
              <div style="width: 30%; height: 100%" class="juz">
                续展率
                <span style="color: #00f8fa; margin-left: 10px">27.28%</span>
              </div>
            </div>
            <div
              style="
                width: 90%;
                height: 20%;
                display: flex;
                align-items: center;
              "
            >
              <div style="width: 30%; height: 100%">
                <img
                  src="../../../public/image/tupianlv3.png"
                  alt=""
                  style="height: 60px; width: 60px"
                />
              </div>
              <div style="width: 30%; height: 100%" class="juz">
                登记数
                <span style="color: #00f8fa; margin-left: 10px">2778</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_center">
        <div class="center_left_1" style="height: 60%">
          <iframe
            src="cfsz.html"
            frameborder="0"
            style="
              width: 100%;
              height: 100%;
              overflow-x: hidden;
              overflow-y: scroll;
            "
          ></iframe>

          <div class="center_left_top">
            <div class="center_left_top1">
              <div
                style="
                  width: 100%;
                  height: 20%;
                  display: flex;
                  align-items: center;
                "
              >
                种植主体（个）
              </div>
              <div
                style="
                  width: 100%;
                  height: 80%;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="1"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="6"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="7"></HelloWorldl>
                </div>
              </div>
            </div>
            <div class="center_left_top1">
              <div
                style="
                  width: 100%;
                  height: 20%;
                  display: flex;
                  align-items: center;
                "
              >
                种植面积（万亩）
              </div>
              <div
                style="
                  width: 100%;
                  height: 80%;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="3"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="5"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="6"></HelloWorldl>
                </div>
              </div>
            </div>
            <div class="center_left_top1">
              <div
                style="
                  width: 100%;
                  height: 20%;
                  display: flex;
                  align-items: center;
                "
              >
                草莓产量（万吨）
              </div>
              <div
                style="
                  width: 100%;
                  height: 80%;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="1"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="3"></HelloWorldl>
                </div>
                <div class="cen_ter_szbj">
                  <HelloWorldl :value="2"></HelloWorldl>
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              position: absolute;
              top: 0;
              position: absolute;
              top: 150px;
              left: 50px;
              font-size: 14px;
            "
          >
            主体立信率：<span style="color: #01fff6; font-size: 18px">80%</span>
          </div>
        </div>
        <div class="center_left_1 cen_bj4" style="height: 39%">
          <div
            style="
              width: 100%;
              height: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            检测
          </div>

          <div id="bar_chartjian" class="chart"></div>
        </div>
      </div>
      <div class="center_right">
        <div
          class="center_left_1 cen_bj5"
          style="
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div
            style="
              width: 100%;
              height: 6%;
              margin-bottom: 2%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            合格证
          </div>
          <div style="width: 90%; height: 90%">
            <div class="hgz_top">
              <div
                style="
                  width: 100%;
                  height: 50%;
                  display: flex;
                  justify-content: space-evenly;
                "
              >
                <div
                  style="
                    width: 30%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                  "
                >
                  合格证打印次数
                </div>
                <div
                  style="
                    width: 60%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                  "
                  class="hgzbj"
                >
                  <div class="hzbj_tit">0</div>
                  <div class="hzbj_tit">{{ this.kjzsll1 }}</div>
                  <div class="hzbj_tit">{{ this.kjzsll2 }}</div>
                  <div class="hzbj_tit">{{ this.kjzsll3 }}</div>
                  <div class="hzbj_tit">{{ this.kjzsll4 }}</div>
                  <div class="hzbj_tit">{{ this.kjzsll5 }}</div>
                  <div class="hzbj_tit">{{ this.kjzsll6 }}</div>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 50%;
                  display: flex;
                  justify-content: space-evenly;
                "
              >
                <div
                  style="
                    width: 30%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                  "
                >
                  合格证开具主体
                </div>
                <div
                  style="
                    width: 60%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  class="hgzbj"
                >
                  <div class="hzbj_tit">0</div>
                  <div class="hzbj_tit">0</div>
                  <div class="hzbj_tit">0</div>
                  <div class="hzbj_tit">0</div>
                  <div class="hzbj_tit">{{ this.ztzs1 }}</div>
                  <div class="hzbj_tit">{{ this.ztzs2 }}</div>
                  <div class="hzbj_tit">{{ this.ztzs3 }}</div>
                </div>
              </div>
            </div>
            <div class="hgz_on">
              <div
                style="
                  width: 50%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <img
                  class="hgz_on_img"
                  src="../../../public/image/dayincis.png"
                  alt=""
                />
                <div class="hgz_on1">
                  <div class="hgz_on_to">{{ this.zjcsll }}</div>
                  <div class="hgz_on_op">合格证查验次数</div>
                </div>
              </div>
              <div
                style="
                  width: 50%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <img
                  class="hgz_on_img"
                  src="../../../public/image/gailv.png"
                  alt=""
                />
                <div class="hgz_on1">
                  <div class="hgz_on_to">{{ this.hglll }}%</div>
                  <div class="hgz_on_op">合格证查验率</div>
                </div>
              </div>
            </div>
            <div class="hgz_bottom">
              <div id="bar_hegezheng" class="chart"></div>
              <div
                style="
                  width: 100%;
                  height: 6%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                "
              >
                合格证开具数统计
              </div>
            </div>
          </div>
        </div>
        <div class="center_left_2 cen_bj6" style="height: 39%">
          <div
            style="
              width: 100%;
              height: 10%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 2%;
            "
          >
            监管
          </div>

          <div class="jianguan">
            <div class="jianguan_tit">
              <div>乡镇</div>
              <div>总检查数</div>
              <div>合格率</div>
              <div>检查数</div>
            </div>

            <div class="jianguan_con">
              <div id="box">
                <div
                  id="con11"
                  ref="con11"
                  :class="{ anim: animate == true }"
                  @mouseenter="mEnter"
                  @mouseleave="mLeave"
                >
                  <div v-for="(item, index) in items" :key="index">
                    <div class="gundong">
                      <span>{{ item.name}}</span>
                      <span style="color: #1ac9ff">{{ item.f }}</span>
                      <span>{{ item.hgl }}%</span>
                      <span style="color: #dd524d">{{ item.f }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import HelloWorldl from "@/components/HelloWorldl.vue";

export default {
  name: "handleindex",

  components: {
    HelloWorldl,
  },
  data() {
    return {
      animate: false,
      items: [
        //消息列表对应的数组
        { name: "", xz: "水湖镇", hg: "100%", bhg: "97%" },
        { name: "", xz: "双墩镇", hg: "98%", bhg: "96%" },
        { name: "", xz: "岗集镇", hg: "98%", bhg: "95%" },
        { name: "", xz: "下塘镇", hg: "95%", bhg: "92%" },
        { name: "", xz: "罗塘乡", hg: "92%", bhg: "92%" },
        { name: "", xz: "吴山镇", hg: "90%", bhg: "88%" },
        { name: "", xz: "陶楼乡", hg: "88%", bhg: "86%" },
      ],
      itemll:[

      ],
      ajcccs: "",
      zfcs: "",
      hgl: "",
      hgzkjs: [],
      iji: 0,
      hglll: "",
      kjzsll: "",
      kjzsll1: "",
      kjzsll2: "",
      kjzsll3: "",
      kjzsll4: "",
      kjzsll5: "",
      kjzsll6: "",
      zjcsll: "",
      ztzs1: "",
      ztzs2: "",
      ztzs3: "",
      ztzsll: "",
    };
  },

  methods: {
    initEchartssj() {
      // console.log("11111111111111")
      // console.log(this.items)

      this.$http.get(ppppl + "/jee5/verson/detection/list").then((res) => {
        //  console.log(res.data.result)
        this.items = res.data.result;
        //  console.log(this.items[0])
      });

      this.$http
        .get(ppppl + "/jee5/verson/enterprise/queryList")
        .then((res) => {
          //  console.log(res.data.result)
          this.zfcs = res.data.result.zfcs;
          this.hgl = res.data.result.hgl;
          this.ajcccs = res.data.result.ajcccs;
          // console.log(this.zfcs)
        });

      this.$http
        .get("http://xinyong.app.zhongtianyun.cn/jee5/verson/certificates/api")
        .then((res) => {
          this.hgzkjs = res.data.result;

          //  console.log(this.hgzkjs)
        });



        this.$http
        .get("https://app2.sinoverse.cn:9099/card/api/app/admin_v35?t=jg_statics&utoken=0edb2ad9aa18462587254834a8c3c0a3&otoken=5ad004f6419b425583bc3aec8914a4ec")
        .then((res) => {
          console.log(this.zjcsll=res.data.count3)

          //  console.log(this.hgzkjs)
        });

        

      this.$http
        .get(ppppl + "/jee5/verson/enterpriseSubject/queryApi")
        .then((res) => {
          // console.log(res.data.result);
          this.hglll = res.data.result.hgl;
          this.kjzsll = res.data.result.kjzs;

          this.ztzsll = res.data.result.ztzs;

          this.kjzsll1 = Math.floor(this.kjzsll / 100000);
          this.kjzsll2 = Math.floor((this.kjzsll % 100000) / 10000);
          this.kjzsll3 = Math.floor(((this.kjzsll % 100000) % 10000) / 1000);
          this.kjzsll4 = Math.floor(
            (((this.kjzsll % 100000) % 10000) % 1000) / 100
          );
          this.kjzsll5 = Math.floor(
            ((((this.kjzsll % 100000) % 10000) % 1000) % 100) / 10
          );
          this.kjzsll6 = Math.floor(
            (((((this.kjzsll % 100000) % 10000) % 1000) % 100) % 10) / 1
          );

          this.ztzs1 = Math.floor(this.ztzsll / 100);
          this.ztzs2 = Math.floor((this.ztzsll % 100) / 10);
          this.ztzs3 = Math.floor(((this.ztzsll % 100) % 10) / 1);
          //  for(var iu=0;iu>){

          //  }
          //  kjzs
        });
    },

    initEcharts() {
      //执法
      var dataArr = [
        {
          value: 8759,
          name: "综合健康评分",
        },
      ];
      console.log(dataArr);
      dataArr[0].value = this.zfcs;
      var color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        {
          offset: 0,
          color: "#086f89", // 0% 处的颜色
        },
        {
          offset: 0.17,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 0.9,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 1,
          color: "#03c9d0", // 100% 处的颜色
        },
      ]);
      var colorSet = [
        [0.91, color],
        [1, "#15337C"],
      ];
      var rich = {
        bule: {
          fontSize: 20,
          fontFamily: "DINBold",
          color: "#fff",
          fontWeight: "700",
          padding: [0, 0, 0, 0],
        },
        radius: {
          width: 50,
          height: 10,
          // lineHeight:80,
          borderWidth: 1,
          borderColor: "#0092F2",
          fontSize: 7,
          color: "#fff",
          backgroundColor: "#1B215B",
          borderRadius: 10,
          textAlign: "center",
        },
        size: {
          height: 400,
          padding: [100, 0, 0, 0],
        },
      };
      var optiond = {
        // backgroundColor: "#0E1327",
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },

        series: [
          {
            //内圆
            type: "pie",
            radius: "85%",
            center: ["50%", "50%"],
            z: 0,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(17,24,43,0)",
                    },
                    {
                      offset: 0.5,
                      // color: '#1E2B57'
                      color: "rgba(28,42,91,.6)",
                    },
                    {
                      offset: 1,
                      color: "#141C33",
                      // color:'rgba(17,24,43,0)'
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
          {
            type: "gauge",
            name: "外层辅助",
            radius: "74%",
            startAngle: "225",
            endAngle: "-45",
            splitNumber: "100",
            pointer: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 1,
              },
            ],
            // data: [{value: 1, name: 90}],
            title: {
              show: true,
              offsetCenter: [0, 30],
              textStyle: {
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "normal",
                fontFamily: "微软雅黑",
                fontSize: 20,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#00FFFF"]],
                width: 1,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: "#051932",
                width: 0,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            type: "gauge",
            radius: "70%",
            startAngle: "225",
            endAngle: "-45",
            pointer: {
              show: false,
            },
            detail: {
              formatter: function (value) {
                var num = Math.round(value);
                return "{bule|" + num + "}" + "{size|" + "}";
              },
              rich: rich,
              offsetCenter: ["0%", "0%"],
            },
            data: dataArr,
            title: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colorSet,
                width: 4,
                // shadowBlur: 15,
                // shadowColor: '#B0C4DE',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              length: 25,
              lineStyle: {
                color: "#00377a",
                width: 2,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            name: "灰色内圈", //刻度背景
            type: "gauge",
            z: 2,
            radius: "60%",
            startAngle: "225",
            endAngle: "-45",
            //center: ["50%", "75%"], //整体的位置设置
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#018DFF"]],
                width: 1,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            // data: [{
            //     show: false,
            //     value: '80'
            // }], //作用不清楚
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          {
            name: "白色圈刻度",
            type: "gauge",
            radius: "80%",
            startAngle: 225, //刻度起始
            endAngle: -45, //刻度结束
            z: 4,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 6, //刻度节点线长度
              lineStyle: {
                width: 1,
                color: "rgba(1,244,255, 0.9)",
              }, //刻度节点线
            },
            axisLabel: {
              color: "rgba(255,255,255,0)",
              fontSize: 12,
            }, //刻度节点文字颜色
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
          {
            //内圆
            type: "pie",
            radius: "56%",
            center: ["50%", "50%"],
            z: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: "#4978EC",
                    },
                    {
                      offset: 0.5,
                      color: "#1E2B57",
                    },
                    {
                      offset: 1,
                      color: "#141F3D",
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
        ],
      };

      echarts.init(document.getElementById("bar_chart1")).setOption(optiond);

      // var myChart12 = echarts.init(document.getElementById("bar_chart1"));

      //执法
      var dataArr = [
        {
          value: 97,
          name: "综合健康评分",
        },
      ];

      dataArr[0].value = this.hgl;
      var color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        {
          offset: 0,
          color: "#5CF9FE", // 0% 处的颜色
        },
        {
          offset: 0.17,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 0.9,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 1,
          color: "#5CF9FE", // 100% 处的颜色
        },
      ]);
      var colorSet = [
        [0.91, color],
        [1, "#15337C"],
      ];
      var rich = {
        white: {
          fontSize: 20,
          color: "#fff",
          fontWeight: "500",
          padding: [0, 0, 0, 0],
        },
        bule: {
          fontSize: 20,
          fontFamily: "DINBold",
          color: "#fff",
          fontWeight: "700",
          padding: [0, 0, 0, 0],
        },
        radius: {
          width: 50,
          height: 10,
          // lineHeight:80,
          borderWidth: 1,
          borderColor: "#0092F2",
          fontSize: 7,
          color: "#fff",
          backgroundColor: "#1B215B",
          borderRadius: 10,
          textAlign: "center",
        },
        size: {
          height: 400,
          padding: [100, 0, 0, 0],
        },
      };
      var optiond = {
        // backgroundColor: "#0E1327",
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },

        series: [
          {
            //内圆
            type: "pie",
            radius: "85%",
            center: ["50%", "50%"],
            z: 0,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(17,24,43,0)",
                    },
                    {
                      offset: 0.5,
                      // color: '#1E2B57'
                      color: "rgba(28,42,91,.6)",
                    },
                    {
                      offset: 1,
                      color: "#141C33",
                      // color:'rgba(17,24,43,0)'
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
          {
            type: "gauge",
            name: "外层辅助",
            radius: "74%",
            startAngle: "225",
            endAngle: "-45",
            splitNumber: "100",
            pointer: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 1,
              },
            ],
            // data: [{value: 1, name: 90}],
            title: {
              show: true,
              offsetCenter: [0, 30],
              textStyle: {
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "normal",
                fontFamily: "微软雅黑",
                fontSize: 20,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#00FFFF"]],
                width: 1,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: "#051932",
                width: 0,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            type: "gauge",
            radius: "70%",
            startAngle: "225",
            endAngle: "-45",
            pointer: {
              show: false,
            },
            detail: {
              formatter: function (value) {
                var num = Math.round(value);
                return "{bule|" + num + "}{white|%}" + "{size|" + "}";
              },
              rich: rich,
              offsetCenter: ["0%", "0%"],
            },
            data: dataArr,
            title: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colorSet,
                width: 4,
                // shadowBlur: 15,
                // shadowColor: '#B0C4DE',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              length: 25,
              lineStyle: {
                color: "#00377a",
                width: 2,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            name: "灰色内圈", //刻度背景
            type: "gauge",
            z: 2,
            radius: "60%",
            startAngle: "225",
            endAngle: "-45",
            //center: ["50%", "75%"], //整体的位置设置
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#018DFF"]],
                width: 1,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            // data: [{
            //     show: false,
            //     value: '80'
            // }], //作用不清楚
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          {
            name: "白色圈刻度",
            type: "gauge",
            radius: "80%",
            startAngle: 225, //刻度起始
            endAngle: -45, //刻度结束
            z: 4,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 6, //刻度节点线长度
              lineStyle: {
                width: 1,
                color: "rgba(1,244,255, 0.9)",
              }, //刻度节点线
            },
            axisLabel: {
              color: "rgba(255,255,255,0)",
              fontSize: 12,
            }, //刻度节点文字颜色
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
          {
            //内圆
            type: "pie",
            radius: "56%",
            center: ["50%", "50%"],
            z: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: "#4978EC",
                    },
                    {
                      offset: 0.5,
                      color: "#1E2B57",
                    },
                    {
                      offset: 1,
                      color: "#141F3D",
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
        ],
      };

      echarts.init(document.getElementById("bar_chart2")).setOption(optiond);

      //执法
      var dataArr = [
        {
          value: 262,
          name: "综合健康评分",
        },
      ];

      dataArr[0].value = this.ajcccs;

      var color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
        {
          offset: 0,
          color: "#5675e1", // 0% 处的颜色
        },
        {
          offset: 0.17,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 0.9,
          color: "#468EFD", // 100% 处的颜色
        },
        {
          offset: 1,
          color: "#7a83e2", // 100% 处的颜色
        },
      ]);
      var colorSet = [
        [0.91, color],
        [1, "#15337C"],
      ];
      var rich = {
        bule: {
          fontSize: 20,
          fontFamily: "DINBold",
          color: "#fff",
          fontWeight: "700",
          padding: [0, 0, 0, 0],
        },
        radius: {
          width: 50,
          height: 10,
          // lineHeight:80,
          borderWidth: 1,
          borderColor: "#0092F2",
          fontSize: 7,
          color: "#fff",
          backgroundColor: "#1B215B",
          borderRadius: 10,
          textAlign: "center",
        },
        size: {
          height: 400,
          padding: [100, 0, 0, 0],
        },
      };
      var optiond = {
        // backgroundColor: "#0E1327",
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },

        series: [
          {
            //内圆
            type: "pie",
            radius: "85%",
            center: ["50%", "50%"],
            z: 0,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(17,24,43,0)",
                    },
                    {
                      offset: 0.5,
                      // color: '#1E2B57'
                      color: "rgba(28,42,91,.6)",
                    },
                    {
                      offset: 1,
                      color: "#141C33",
                      // color:'rgba(17,24,43,0)'
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
          {
            type: "gauge",
            name: "外层辅助",
            radius: "74%",
            startAngle: "225",
            endAngle: "-45",
            splitNumber: "100",
            pointer: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 1,
              },
            ],
            // data: [{value: 1, name: 90}],
            title: {
              show: true,
              offsetCenter: [0, 30],
              textStyle: {
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "normal",
                fontFamily: "微软雅黑",
                fontSize: 20,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#00FFFF"]],
                width: 1,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: "#051932",
                width: 0,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            type: "gauge",
            radius: "70%",
            startAngle: "225",
            endAngle: "-45",
            pointer: {
              show: false,
            },
            detail: {
              formatter: function (value) {
                var num = Math.round(value);
                return "{bule|" + num + "}" + "{size|" + "}";
              },
              rich: rich,
              offsetCenter: ["0%", "0%"],
            },
            data: dataArr,
            title: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colorSet,
                width: 4,
                // shadowBlur: 15,
                // shadowColor: '#B0C4DE',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              length: 25,
              lineStyle: {
                color: "#00377a",
                width: 2,
                type: "solid",
              },
            },
            axisLabel: {
              show: false,
            },
          },
          {
            name: "灰色内圈", //刻度背景
            type: "gauge",
            z: 2,
            radius: "60%",
            startAngle: "225",
            endAngle: "-45",
            //center: ["50%", "75%"], //整体的位置设置
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#018DFF"]],
                width: 1,
                opacity: 1, //刻度背景宽度
              },
            },
            splitLine: {
              show: false,
            },
            // data: [{
            //     show: false,
            //     value: '80'
            // }], //作用不清楚
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            detail: {
              show: 0,
            },
          },
          {
            name: "白色圈刻度",
            type: "gauge",
            radius: "80%",
            startAngle: 225, //刻度起始
            endAngle: -45, //刻度结束
            z: 4,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: 6, //刻度节点线长度
              lineStyle: {
                width: 1,
                color: "rgba(1,244,255, 0.9)",
              }, //刻度节点线
            },
            axisLabel: {
              color: "rgba(255,255,255,0)",
              fontSize: 12,
            }, //刻度节点文字颜色
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
          {
            //内圆
            type: "pie",
            radius: "56%",
            center: ["50%", "50%"],
            z: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: "#4978EC",
                    },
                    {
                      offset: 0.5,
                      color: "#1E2B57",
                    },
                    {
                      offset: 1,
                      color: "#141F3D",
                    },
                  ],
                  false
                ),
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: [100],
          },
        ],
      };

      echarts.init(document.getElementById("bar_chart3")).setOption(optiond);

      //检测
      let dataX = [
        "人防办",
        "人防办",
        "人防办",
        "人防办",
        "人防办",
        "人防办",
        "人防办",
        "人防办",
      ];

      let dataY = [98, 38, 48, 35, 92, 28, 93, 85];
      let dataY1 = [400, 500, 300, 300, 300, 400, 400, 400, 300];

      // console.log(dataX[0]);
      // console.log(dataY);
      // console.log(dataY1);
      // console.log(this.items);
      for (var q = 0; q < this.items.length; q++) {
        console.log("1111111111");
        dataX[q] = this.items[q].name;
        dataY[q] = this.items[q].hgl;
        dataY1[q] = this.items[q].f;
      }
      // console.log(dataX);
      // console.log(dataY);
      // console.log(dataY1);
      console.log(this.items);

      var optiondjian = {
        // backgroundColor: '#0D2753',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return (
              dataX[params[0].dataIndex] +
              "<br/> 合格率：" +
              dataY[params[0].dataIndex] +
              "%" +
              "<br> 检测次数：" +
              dataY1[params[0].dataIndex] +
              ""
            );
          },
        },
        grid: {
          top: "15%",
          bottom: "5%",
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          data: ["检测次数", "合格率"],
          left: "15",
          top: "15",
          textStyle: {
            padding: [4, 0, 0, 0],
            color: "33FFFF",
          },
          itemWidth: 15,
          itemHeight: 10,
          itemGap: 25,
        },
        xAxis: {
          type: "category",
          data: dataX,
          axisLine: {
            lineStyle: {
              color: "#858eaa",
            },
          },

          axisLabel: {
            rotate: 0,
            fontSize: 10,
            textStyle: {
              color: "#858eaa",
            },
          },
        },

        yAxis: [
          {
            type: "value",
            name: "检测次数（次）",
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#858eaa",
              },
            },
            axisLine: {
              lineStyle: {
                fontSize: 12,
                color: "#858eaa",
              },
            },
          },
          {
            type: "value",
            name: "合格率（%）",
            nameTextStyle: {
              color: "#858eaa",
            },
            max: "100",
            min: "0",
            scale: true,
            position: "right",
            axisLine: {
              lineStyle: {
                color: "#858eaa",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              formatter: "{value} ", //右侧Y轴文字显示
              textStyle: {
                fontSize: 12,
                color: "#858eaa",
              },
            },
          },
        ],
        series: [
          {
            // name: '回访数量',
            type: "bar",
            barWidth: "12px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00eefc",
                  },
                  {
                    offset: 1,
                    color: "#0053aa",
                  },
                ]),
                // barBorderRadius: 6,
              },
            },
            data: dataY1,
          },
          {
            // name: '满意率',
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: false, //平滑曲线显示

            symbol: "circle", //标记的图形为实心圆
            symbolSize: 3, //标记的大小
            itemStyle: {
              normal: {
                color: "#27f5e2",
                borderColor: "#ffffff", //圆点透明 边框
                borderWidth: 4,
              },
            },
            lineStyle: {
              color: "#27f5e2",
            },

            data: dataY,
          },
        ],
      };
      echarts
        .init(document.getElementById("bar_chartjian"))
        .setOption(optiondjian);

      //合格证
      // 绘制左侧面
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          // 会canvas的应该都能看得懂，shape是从custom传入的
          const xAxisPoint = shape.xAxisPoint;
          const c0 = [shape.x, shape.y];
          const c1 = [shape.x - 13, shape.y - 13];
          const c2 = [xAxisPoint[0] - 13, xAxisPoint[1] - 13];
          const c3 = [xAxisPoint[0], xAxisPoint[1]];
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath();
        },
      });
      // 绘制右侧面
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c1 = [shape.x, shape.y];
          const c2 = [xAxisPoint[0], xAxisPoint[1]];
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9];
          const c4 = [shape.x + 18, shape.y - 9];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 绘制顶面
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x + 18, shape.y - 9];
          const c3 = [shape.x + 5, shape.y - 22];
          const c4 = [shape.x - 13, shape.y - 13];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 注册三个面图形
      echarts.graphic.registerShape("CubeLeft", CubeLeft);
      echarts.graphic.registerShape("CubeRight", CubeRight);
      echarts.graphic.registerShape("CubeTop", CubeTop);

      var _this = this;
      var ij = _this.hgzkjs.length;
      console.log(ij);
      var hjzcs1 = [];
      var hjztime = [];
      var sjcs=7;

      for (var opo = ij - 1; opo >= 0; opo--) {
        if (_this.iji <= 7) {
          hjzcs1[sjcs] = _this.hgzkjs[opo].c;
          hjztime[sjcs] = _this.hgzkjs[opo].k;
          console.log(_this.hgzkjs[opo]);
          _this.iji = _this.iji + 1;
          sjcs=sjcs-1
        }
      }

      console.log(hjzcs1);
      console.log(hjztime);

      const MAX = [50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000];
      const VALUE = [210.9, 260.8, 204.2, 504.9, 740.5, 600.3, 119.0];
      var optionhegezheng = {
        // backgroundColor: "#012366",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params, ticket, callback) {
            const item = params[1];
            return item.name + " : " + item.value;
          },
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 10,
          top: 30,
          containLabel: true,
        },
        xAxis: {
          name: "日期",
          type: "category",
          data: hjztime,
          axisLine: {
            show: true,
            lineStyle: {
              color: "white",
            },
          },
          offset: 5,
          axisTick: {
            show: false,
            length: 9,
            alignWithLabel: true,
            lineStyle: {
              color: "#7DFFFD",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 7,
          },
        },
        yAxis: {
          name: "开具数（枚）",
          min: 0,
          max: 55000,
          interval: 10000,
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              color: "white",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 10,
          },
          boundaryGap: ["20%", "20%"],
        },
        series: [
          {
            type: "custom",

            renderItem: function (params, api) {
              const location = api.coord([api.value(0), api.value(1)]);
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: "rgba(47,102,192,.27)",
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: "rgba(59,128,226,.27)",
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: "rgba(72,156,221,.27)",
                    },
                  },
                ],
              };
            },
            data: MAX,
          },
          {
            type: "custom",
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)]);
              var color =
                api.value(1) > 55000
                  ? "red"
                  : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#27f7e1",
                      },
                      {
                        offset: 0.8,
                        color: "#1A85FD",
                      },
                    ]);
              return {
                type: "group",
                children: [
                  {
                    type: "CubeLeft",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeRight",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                  {
                    type: "CubeTop",
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]),
                    },
                    style: {
                      fill: color,
                    },
                  },
                ],
              };
            },

            data: hjzcs1,
          },
        ],
      };
      echarts
        .init(document.getElementById("bar_hegezheng"))
        .setOption(optionhegezheng);
    },

    scroll() {
      //建一个方法
      // let con1 = this.$refs.con1;
      var con11=document.getElementById("con11")
      // console.log(con1.style.marginTop);
      con11.style.marginTop = "-55px"; //设置style样式 向上移动30px
      this.animate = !this.animate; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.items.push(that.items[0]); //尾部追加数组的第一个，放到数组最后
        that.items.shift(); //删除第一个元素
        con11.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animate = !that.animate; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnter() {
      clearInterval(this.timer1); //鼠标移入清除定时器
    },
    mLeave() {
      this.timer1 = setInterval(this.scroll, 1000); //鼠标离开启动定时器，执行 scroll
    },
  },

  mounted() {
    var _this = this;
   
    _this.initEchartssj();

     setTimeout(function () {
      _this.initEcharts();
    }, 7000);

    this.timer1 = setInterval(this.scroll, 1000); //setInterval定时器，当页面加载完执行定时器
  },
};
</script>


<style scoped>
@media screen and (min-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }
  .center {
    width: 102%;
    margin-top: 1%;
    /* background-color: aqua; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 27%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 18px;
  }
  .center_center {
    width: 44%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 18px;
  }
  .center_right {
    width: 27%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 18px;
  }
  .center_left_1 {
    width: 100%;
    height: 27%;
    /* background-color: brown; */
    position: relative;
  }
  .cen_bj1 {
    background: url(../../../public/image/jianjie.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj2 {
    background: url(../../../public/image/jianjie1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cen_bj3 {
    background: url(../../../public/image/jianjie2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj4 {
    background: url(../../../public/image/jianjie3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj5 {
    background: url(../../../public/image/jianjie4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj6 {
    background: url(../../../public/image/jianjie5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .center_left_2 {
    width: 100%;
    height: 32%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center_left_3 {
    width: 100%;
    height: 39%;
    /* background-color: brown; */
  }
  .cen_tit {
    font-size: 16px;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cen_tit_on {
    width: 90%;
    height: 80%;
    text-align: left;
    text-indent: 6%;
  }
  .center_left_3xia {
    width: 100%;
    height: 90%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 15px;
  }
  .juz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hgz_top {
    width: 100%;
    height: 20%;
    font-size: 15px;
  }
  .hgz_on {
    width: 100%;
    height: 20%;
    display: flex;
  }
  .hgz_bottom {
    width: 100%;
    height: 60%;
  }
  .hgzbj {
    background: url(../../../public/image/hegzbeijing.png);
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }
  .hzbj_tit {
    width: 10%;
    height: 60%;
    background: url(../../../public/image/beijxx.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
    font-size: 16px;
  }
  .hgz_on_img {
    width: 70px;
    height: 60px;
  }
  .hgz_on1 {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .hgz_on_to {
    font-size: 20px;
    color: #24dcd6;
    margin-bottom: 1%;
  }
  .hgz_on_op {
    font-size: 12px;
    color: #949aab;
  }
  .jianguan {
    width: 90%;
    height: 90%;
  }
  .jianguan_tit {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #52ccff;
  }
  .jianguan_con {
    width: 100%;
    height: 290px;
  }

  #box {
    /* margin: 0 auto;  */
    width: 100%;
    height: 89%;
    line-height: 50px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
  }
  .anim {
    transition: all 0.5s;
  }
  #con1 li {
    list-style: none;
    line-height: 50px;
    height: 50px;
  }
  .gundong {
    width: 100%;
    height: 90%;
    background: url(../../../public/image/gundong.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
    margin-bottom: 2%;
  }
  .center_left_top {
    width: 100%;
    height: 25%;
    /* background-color: #24dcd6; */
    position: absolute;
    top: 11px;
    display: flex;
    justify-content: space-evenly;
  }
  .center_left_top1 {
    width: 20%;
    height: 80%;
    /* background-color: brown; */
    font-size: 14px;
  }

  .cen_ter_szbj {
    width: 30%;
    height: 80%;
    background: url(../../../public/image/szbj.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    color: #01fff6;
  }
}

/* 小于1400px */
@media screen and (max-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }

  .center {
    width: 102%;
    /* background-color: aqua; */
    /* margin-top: 1%; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 27%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 15px;
  }
  .center_center {
    width: 44%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 15px;
  }
  .center_right {
    width: 27%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    font-size: 15px;
  }
  .center_left_1 {
    width: 100%;
    height: 27%;
    /* background-color: brown; */
    position: relative;
  }
  .center_left_2 {
    width: 100%;
    height: 32%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center_left_3 {
    width: 100%;
    height: 39%;
    /* background-color: brown; */
  }
  .cen_bj1 {
    background: url(../../../public/image/jianjie.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj2 {
    background: url(../../../public/image/jianjie1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cen_bj3 {
    background: url(../../../public/image/jianjie2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj4 {
    background: url(../../../public/image/jianjie3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj5 {
    background: url(../../../public/image/jianjie4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_bj6 {
    background: url(../../../public/image/jianjie5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_tit {
    font-size: 0.01em;
    margin-top: -10px;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cen_tit_on {
    width: 95%;
    height: 80%;
    text-align: left;
    text-indent: 6%;
    font-size: 15px;
  }
  .center_left_3xia {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 15px;
  }
  .juz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hgz_top {
    width: 100%;
    height: 20%;
    font-size: 12px;
  }
  .hgz_on {
    width: 100%;
    height: 20%;
    display: flex;
  }
  .hgz_bottom {
    width: 100%;
    height: 60%;
  }
  .hgzbj {
    background: url(../../../public/image/hegzbeijing.png);
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }
  .hzbj_tit {
    width: 10%;
    height: 60%;
    background: url(../../../public/image/beijxx.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
    font-size: 16px;
  }
  .hgz_on_img {
    width: 60px;
    height: 50px;
  }
  .hgz_on1 {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .hgz_on_to {
    font-size: 18px;
    color: #24dcd6;
    margin-bottom: 1%;
  }
  .hgz_on_op {
    font-size: 10px;
    color: #949aab;
  }
  .jianguan {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
    flex-direction: column;
  }
  .jianguan_tit {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #52ccff;
  }
  .jianguan_con {
    width: 100%;
    height: 200px;
  }

  #box {
    /* margin: 0 auto;  */
    width: 100%;
    height: 94%;
    line-height: 50px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
  }
  .anim {
    transition: all 0.5s;
  }
  #con1 li {
    list-style: none;
    line-height: 50px;
    height: 50px;
  }

  .gundong {
    width: 100%;
    height: 90%;
    background: url(../../../public/image/gundong.png);
    background-size: 100% 80%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
  }
  .center_left_top {
    width: 100%;
    height: 25%;
    /* background-color: #24dcd6; */
    position: absolute;
    top: 11px;
    display: flex;
    justify-content: space-evenly;
  }
  .center_left_top1 {
    width: 20%;
    height: 80%;
    /* background-color: brown; */
    font-size: 12px;
  }
  .cen_ter_szbj {
    width: 30%;
    height: 80%;
    background: url(../../../public/image/szbj.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    color: #01fff6;
  }
}
</style>







