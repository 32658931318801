<template>
  <div class="number-grow-warp">
    <span
      ref="numberGrow"
      :data-time="time"
      class="number-grow"
      :data-value="value"
      >0</span
    >
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 1,
    },
    value: {
      type: Number,
      default: 720000,
    },
  },
  methods: {
    numberGrow(ele) {
      
      let _this = this;

      let step = (_this.value * 10) / (_this.time * 1000);
      let current = 0;
      let start = 0;
      let t = setInterval(function () {
        start += step;
        if (start > _this.value) {
          clearInterval(t);
          start = _this.value;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = start;
        // console.log(current.toString().split(".")[0])
        ele.innerHTML = current.toString().split(".")[0];
      }, 10);
    },
  },
  mounted() {
    this.numberGrow(this.$refs.numberGrow);
    // console.log(JSON.stringify(this.chongzhiguizeData.info))
  },
};
</script>

<style>
@media screen and (min-width: 1600px) {
  .number-grow-warp {
    transform: translateZ(0);
  }
  .number-grow {
    /* font-family: Arial-BoldMT; */
    /* font-size: 64px; */
    /* font-size: 36px; */
    /* margin-right: 15px; */
    /* color: #ffaf00;
  letter-spacing: 2.67px;
  margin:110px 0 20px;
  display: block;
  line-height:64px; */
  }
}

/* 小于1400px */
@media screen and (max-width: 1600px) {
  .number-grow-warp {
    transform: translateZ(0);
  }
  .number-grow {
    /* font-family: Arial-BoldMT; */
    /* font-size: 64px; */
    /* font-size: 25px; */
    /* margin-right: 15px; */
    /* color: #ffaf00;
  letter-spacing: 2.67px;
  margin:110px 0 20px;
  display: block;
  line-height:64px; */
  }
}
</style>