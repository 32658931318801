<template>
  <div class="home">
    <div class="center">
      <div class="center_left">
        <div class="center_left_1 bj1" style="height: 32%">
          <div class="cen_tit">信用主体</div>
          <div class="cen_zt">
            <div class="cen_zt_1">
              <div id="bar_chart11" class="chart"></div>
            </div>
            <div class="cen_zt_2">
              <div class="cen_zt_2_1">
                总数：<span style="font-size: 20px">{{this.zs}}</span>
              </div>
              <div class="cen_zt_2_2">
                <div style="width: 100%; height: 50%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="../../../public/image/aj.png"
                      alt=""
                      style="margin-right: 10px;    width: 10px;height: 10px;"
                    />
                    A级：{{this.aj}}
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="../../../public/image/bj.png"
                      alt=""
                      style="margin-right: 10px;    width: 10px;height: 10px;"
                    />
                    B级：{{this.bj}}
                  </div>
                </div>
                <div style="width: 100%; height: 50%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="../../../public/image/cj.png"
                      alt=""
                      style="margin-right: 10px;    width: 10px;height: 10px;"
                    />
                    C级：{{this.cj}}
                  </div>
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="../../../public/image/dj.png"
                      alt=""
                      style="margin-right: 10px;    width: 10px;height: 10px;"
                    />
                    D级：{{this.dj}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center_left_2 bj2" style="height: 67%">
          <div class="cen_tit" style="height: 7%">主体名录</div>
          <div style="width: 90%; height: 89%">
            <!-- vue 实现无限向上滚动 -->
            <div id="box">
              <div
                id="con1"
                ref="con1"
                :class="{ anim: animate == true }"
                @mouseenter="mEnterl"
                @mouseleave="mLeavel"
              >
                <div v-for="(item, index) in items" :key="index">
                  <div class="ztmllb">
                    <div style="width: 65%;padding-left: 5%;height: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-evenly;">
                      <p style="font-size:15px">{{ item.name }}</p>
                      <p>{{item.address}}</p>
                      <p>联系电话：{{item.tel}}</p>
                      <p>检查次数：{{item.cn}}/合格率：{{item.ihgv}}</p>
                      <p>合格证数：{{item.hgzm}}枚</p>
                    

                    </div>
                    <div style="width: 30%; height: 100%;    display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                      <div style="width: 49%;height: 16%;background-color: #44b1f9;color: #000000;border-radius: 9px;">{{item.cn}}/年</div>
                      <div style="width: 38%;">
                        <div>
                          信用评分

                        </div>

                        <div> 
                          {{item.pf}}分 {{item.jis}}

                        </div>
                        
                      </div>

                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center_center">
        <div class="center_left_1 " style="height: 100%;    display: flex;align-items: center;    position: relative;justify-content: flex-end;">
          <!-- <img src="image/ditu.png" alt="" style="height: 90%;width: 65%;"> -->
          <div class="bjmapll">
            <div >网格化监管人员：<span style="color: #65e7ff;font-size: 21px;">30人</span></div>
            <div >网格化监管单位：<span style="color: #65e7ff;font-size: 21px;">14家</span></div>
            
          </div>

          <div class="pmyu" style="z-index: 9;    width: 30%;height: 60%;background: url(/image/xz5.png);position: absolute;left: 3%; bottom: 0%;background-size: 100% 100%;background-repeat: no-repeat;    display: flex;flex-direction: column;justify-content: center;align-items: center;">

                <div class="pmk">
                  <div class="pmk_nr">TOP</div>
                  <div class="pmk_nr">乡镇</div>
                  <div class="pmk_nr">监管主体</div>
                  <div class="pmk_nr">监管次数</div>
                </div>
                <div class="pmk" style="font-size:15px;color:red;">
                  <div class="pmk_nr">1</div>
                  <div class="pmk_nr">{{itemszt[12].name}}</div>
                  <div class="pmk_nr">{{itemszt[12].c}}</div>
                  <div class="pmk_nr">{{itemszt[12].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;color:#CE7F63;">
                  <div class="pmk_nr">2</div>
                  <div class="pmk_nr">{{itemszt[11].name}}</div>
                  <div class="pmk_nr">{{itemszt[11].c}}</div>
                  <div class="pmk_nr">{{itemszt[11].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;color:#D1AC6B;">
                  <div class="pmk_nr">3</div>
                  <div class="pmk_nr">{{itemszt[6].name}}</div>
                  <div class="pmk_nr">{{itemszt[6].c}}</div>
                  <div class="pmk_nr">{{itemszt[6].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">4</div>
                  <div class="pmk_nr">{{itemszt[7].name}}</div>
                  <div class="pmk_nr">{{itemszt[7].c}}</div>
                  <div class="pmk_nr">{{itemszt[7].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">5</div>
                  <div class="pmk_nr">{{itemszt[5].name}}</div>
                  <div class="pmk_nr">{{itemszt[5].c}}</div>
                  <div class="pmk_nr">{{itemszt[5].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">6</div>
                  <div class="pmk_nr">{{itemszt[8].name}}</div>
                  <div class="pmk_nr">{{itemszt[8].c}}</div>
                  <div class="pmk_nr">{{itemszt[8].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">7</div>
                  <div class="pmk_nr">{{itemszt[13].name}}</div>
                  <div class="pmk_nr">{{itemszt[13].c}}</div>
                  <div class="pmk_nr">{{itemszt[13].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">8</div>
                  <div class="pmk_nr">{{itemszt[9].name}}</div>
                  <div class="pmk_nr">{{itemszt[9].c}}</div>
                  <div class="pmk_nr">{{itemszt[9].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">9</div>
                  <div class="pmk_nr">{{itemszt[10].name}}</div>
                  <div class="pmk_nr">{{itemszt[10].c}}</div>
                  <div class="pmk_nr">{{itemszt[10].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">10</div>
                  <div class="pmk_nr">{{itemszt[1].name}}</div>
                  <div class="pmk_nr">{{itemszt[1].c}}</div>
                  <div class="pmk_nr">{{itemszt[1].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">11</div>
                  <div class="pmk_nr">{{itemszt[2].name}}</div>
                  <div class="pmk_nr">{{itemszt[2].c}}</div>
                  <div class="pmk_nr">{{itemszt[2].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">12</div>
                  <div class="pmk_nr">{{itemszt[3].name}}</div>
                  <div class="pmk_nr">{{itemszt[3].c}}</div>
                  <div class="pmk_nr">{{itemszt[3].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">13</div>
                  <div class="pmk_nr">{{itemszt[4].name}}</div>
                  <div class="pmk_nr">{{itemszt[4].c}}</div>
                  <div class="pmk_nr">{{itemszt[4].c9}}</div>
                </div>
                <div class="pmk" style="font-size:15px;">
                  <div class="pmk_nr">14</div>
                  <div class="pmk_nr">{{itemszt[14].name}}</div>
                  <div class="pmk_nr">{{itemszt[14].c}}</div>
                  <div class="pmk_nr">{{itemszt[14].c9}}</div>
                </div>

          </div>

          <HandleWorldmap></HandleWorldmap>



          <!-- <div style="    width: 40px;height: 20px;position: absolute;top: 0;left: 0;background: red;"></div> -->



        </div>
        <!-- <div class="center_left_1" style="height: 40%;"></div> -->
      </div>
      <div class="center_right">
        <div class="center_left_1 bj3" style="height: 67%;    display: flex;flex-direction: column;align-items: center;">
          <div class="cen_tit" style="height: 8%">监督检查</div>




          <div style="    width: 85%;height: 15%;display: flex;justify-content: space-between;margin-top: 3%;">
            <div class="jiandusi" style="">
              <div style="color:#d7b41c;font-size:20px;display: flex;align-items: center;"><HelloWorldl :value="this.lx" :time="0.5"></HelloWorldl> <span style="font-size:16px">次</span> </div>
              <div style="margin-top: 6%;">例行检查</div>
                

            </div>
            <div class="jiandusi" style="">
              <div style="color:#1e92e1;font-size:25px;display: flex;align-items: center;"><HelloWorldl :value="this.hgz" ></HelloWorldl> <span style="font-size:16px">次</span> </div>
              <div style="margin-top: 6%;">合格证检查</div>

            </div>
            <div class="jiandusi" style="">
              <div style="color:#ed7575;font-size:25px;display: flex;align-items: center;"><HelloWorldl :value="this.rc"></HelloWorldl> <span style="font-size:16px">次</span> </div>
              <div style="margin-top: 6%;">日常检查</div>

            </div>
            <div class="jiandusi" style="">
              <div style="color:#5742b9;font-size:25px;display: flex;align-items: center;"><HelloWorldl :value="this.bhg"></HelloWorldl> <span style="font-size:16px">次</span> </div>
              <div style="margin-top: 6%;">检查不合格</div>

            </div>

          </div>
          <div style="width: 85%; height: 65%;margin-top: 3%;background-color: #0a1f5d;">
            <HolloWordbanner></HolloWordbanner>

          </div>








        </div>
        <div class="center_left_2 bj4" style="height: 32%;    display: flex;flex-direction: column;align-items: center;">
          <div class="cen_tit" style="height: 8%;margin-top: 1%;">农事动态</div>

          <div style="width: 90%; height: 90%;">
             <!-- vue 实现无限向上滚动 -->
              <div id="boxw">
                <div
                  id="con1w"
                  ref="con1w"
                  :class="{ animw: animatew == true }"
                  @mouseenter="mEnterw"
                  @mouseleave="mLeavew"
                >
                  <div v-for="(item, index) in itemsw" :key="index">
                    <div class="ztmllw">

                      <div style="width: 15%; height: 100%;">
                        <img :src="item.video_thumb" alt="" style="width: 100%; height: 100%;">

                      </div>
                      <div style="width: 77%; height: 100%;display: flex;flex-direction: column;margin-left: 3%;">
                        <div style="width: 100%; height: 50%; display: flex;justify-content:flex-start; font-size:14px;color:#ffffff;">
                          {{ item.name }}
                        </div>
                        <div style="width: 100%; height: 50%; display: flex;justify-content:space-between;">
                          <div style="font-size:12px;color:#40a7ee;">{{ item.result }}</div>
                          <div style="font-size:12px;color:#a4a8b3;">{{ item.datetime }}</div>
                        </div>

                      </div>

                    </div>
                    
                  </div>
                </div>
              </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import HolloWordbanner from "@/components/HolloWordbanner.vue";
import HandleWorldmap from "@/components/HandleWorldmap.vue";
// import elementResizeDetectorMaker from "element-resize-detector";
import HelloWorldl from "@/components/HelloWorldl.vue";

export default {
  name: "handleindex",

  components: {
    HolloWordbanner,
    HandleWorldmap,
    HelloWorldl
  },
  data() {
    return {
      animate: false,
      itemszt:[],
      itemszs:[],
      itemssl:[],
      items: [
        //消息列表对应的数组
        { name: "安徽裕旺农业科技发展有限公司",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司1",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司2",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司3",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司4",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司5",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
        { name: "安徽裕旺农业科技发展有限公司6",tel:"138****7934",address:"合肥市长丰县造甲乡双丰社区",jccs:"1次",hgl:"100%",hgzm:"0",cn:"1",pf:"61",jis:"B" },
      ],
      aj:0,
      bj:0,
      cj:0,
      dj:0,
      zs:0,


      bhg:0,
      hgz:1,
      lx:2,
      rc:3,

      animatew: false,
      itemsw: [
        //消息列表对应的数组
        { name: "安徽裕旺农业科技发展有限公司",img:"t1.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29",},
        { name: "安徽裕旺农业科技发展有限公司1",img:"t2.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
        { name: "安徽裕旺农业科技发展有限公司2",img:"t3.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
        { name: "安徽裕旺农业科技发展有限公司3",img:"t4.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
        { name: "安徽裕旺农业科技发展有限公司4",img:"t2.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
        { name: "安徽裕旺农业科技发展有限公司5",img:"t3.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
        { name: "安徽裕旺农业科技发展有限公司6",img:"t4.png",zt:"青年，男性，休息", rq:"01-12",sj:"8:29", },
      ],
    };
  },

  methods: {



    handlesj(){
      var _this=this

      this.$http.get('https://app2.sinoverse.cn:9099/card/api/app/admin_v35?t=jg_statics_two&areaId=86340121&utoken=fa6145343bc840d3b77e393bd68fa773&otoken=8b9eadda31b345878246507ea534c26a')
                .then( (res) => {
                  // console.log(res.data.list2);


                  this.itemszt=res.data.list2

                    console.log(this.itemszt)

                    this.$http.get('https://app2.sinoverse.cn:9099/card/api/app/admin_v35?t=jg_statics_one&areaId=86340121&utoken=fa6145343bc840d3b77e393bd68fa773&otoken=8b9eadda31b345878246507ea534c26a')
                      .then( (res) => {
                        console.log(res.data)

                        console.log(this.itemszt)
                        

                          for(var li=0;li<this.itemszt.length;li++){
                                  for(var lo=0;lo<res.data.list2.length;lo++){
                                    if(_this.itemszt[li].id==res.data.list2[lo].id){
                                      _this.itemszt[li].c9=res.data.list2[lo].v
                                      // console.log(res.data.list2[lo].v)
                                      console.log(_this.itemszt)
                                    }

                                  }
                                }


          


              
          
                      // console.log(this.bhg)
                      // console.log(this.hgz)
                      // console.log(this.lx)
                      // console.log(this.rc)

                    })


                 
                  



                  
                  // console.log(_this.itemszt)

                  
                  // console.log(this.bhg)
                  // console.log(this.hgz)
                  // console.log(this.lx)
                  // console.log(this.rc)

                })



                // setTimeout(function (){
                //   for(var io=0;io<_this.itemszt.length;io++){
                //     for(){

                //     }
                //     if(_this.itemszt[io].c>_this.itemszs[io-1].c){

                //     }

                //   }
                // },60000)




      



         








         this.$http.get(ppppl+'/jee5/verson/inspect/queryList')
        .then( (res) => {
          //  console.log(res.data.result)

          this.bhg=res.data.result[0].bhg
          this.hgz=res.data.result[0].hgz
          this.lx=res.data.result[0].lx
          this.rc=res.data.result[0].rc
          // console.log(this.bhg)
          // console.log(this.hgz)
          // console.log(this.lx)
          // console.log(this.rc)

         })






         this.$http.get(ppppl+'/jee5/verson/evaluation/queryList')
        .then( (res) => {
           console.log(res.data.result)
           _this.aj=res.data.result.A
           _this.bj=res.data.result.B
           _this.cj=res.data.result.C
           _this.dj=res.data.result.D
           _this.zs=res.data.result.zs
          //  console.log(_this.aj)
          //  console.log(_this.bj)
          //  console.log(_this.cj)
          //  console.log(_this.dj)
 


         })


         this.$http.get(ppppl+'/jee5/verson/evaluation/api')
        .then( (res) => {
           console.log(res.data.result)
           this.items=res.data.result
          
          //  console.log(_this.bj)
          //  console.log(_this.cj)
          //  console.log(_this.dj)
 


         })


         this.$http.get(ppppl+'/jee5/verson/wugan/list')
        .then( (res) => {
           console.log(res.data.result)


           this.itemsw=res.data.result
          //  this.items=res.data.result
          
          //  console.log(_this.bj)
          //  console.log(_this.cj)
          //  console.log(_this.dj)
 


         })

         



      },


    // initEchartsll(){
    //   var aaa=[]
    //   var aaa=document.querySelectorAll("div")
    //   console.log(aaa[100].innerHTML="<span style='float:left;magin-top:50px;width:195px;height:35px;line-height:25px;color:#ffffff;'>&nbsp;&nbsp;闲散总面积：万亩</span>")



      



    // },
    initEcharts() {
      var colors = ["#44b3f5", "#8b5bf9", "#fb7d7f", "#ddb94c"].reverse();
      var _this=this
      var datas = [
        {
          name: "D级",
          value: 72,
        },
        {
          name: "C级",
          value: 54,
        },
        {
          name: "B级",
          value: 22,
        },
        {
          name: "A级",
          value: 18,
        },
      ];
      datas[0].value=_this.dj
      datas[1].value=_this.cj
      datas[2].value=_this.bj
      datas[3].value=_this.aj
      // console.log(datas)
      // var total = datas.reduce((prev, curr) => prev + curr.value, 0);
      var optiond = {


        // backgroundColor: '#031d33',
        color:['#E6BB55','#FC7F7C','#855EFA ',"#429CF4 "],
        tooltip: {
            show: true,
        },
        series: [
            {
                type: 'pie',
                radius: '80%',
                center: ['50%', '50%'],
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        borderColor: '#003359',
                    },
                },
                label: {
                    show: true,
                    position: 'inside',
                    align: 'center',
                    verticalAlign: 'middle',
                    formatter(params) {
                        console.log('label params', params);
                        // return params.value;
                        return `{name|${params.name}}\n {value|${params.value}}`;
                    },
                    rich: {
                        name: {
                            fontSize: 20,
                            color:"#ffffff"
                        },
                        value: {
                            fontSize: 30,
                            lineHeight:40,
                            color:"#ffffff"
                        },
                    },
                },
                data: datas,
                labelLine: {
                    show: false,
                },
            },
        ],
















      };

      echarts.init(document.getElementById("bar_chart11")).setOption(optiond);
    },

    scrolll() {
      //建一个方法
      // var con1 = this.$refs.con1;
      var con1=document.getElementById("con1")
      con1.style.marginTop = "-170px"; //设置style样式 向上移动30px
      this.animate = !this.animate; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.items.push(that.items[0]); //尾部追加数组的第一个，放到数组最后
        that.items.shift(); //删除第一个元素
        con1.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animate = !that.animate; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnterl() {
      clearInterval(this.timer1l); //鼠标移入清除定时器
    },
    mLeavel() {
      this.timer1l = setInterval(this.scrolll, 1000); //鼠标离开启动定时器，执行 scroll
    },



    scrollw() {
      //建一个方法
      // var con1w = this.$refs.con1w;
      var con1w=document.getElementById("con1w")
      // console.log(con1w)
      con1w.style.marginTop = "-60px"; //设置style样式 向上移动30px
      this.animatew = !this.animatew; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.itemsw.push(that.itemsw[0]); //尾部追加数组的第一个，放到数组最后
        that.itemsw.shift(); //删除第一个元素
        con1w.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animatew = !that.animatew; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnterw() {
      clearInterval(this.timer1w); //鼠标移入清除定时器
    },
    mLeavew() {
      this.timer1w = setInterval(this.scrollw, 1000); //鼠标离开启动定时器，执行 scroll
    },
  },

  mounted() {
    var _this=this
    _this.handlesj()
    setTimeout(function () {
      console.log("1111111111")
      _this.initEcharts();
    }, 2000);
    // this.initEchartsll()
    
    this.timer1l = setInterval(this.scrolll, 3000); //setInterval定时器，当页面加载完执行定时器
    this.timer1w = setInterval(this.scrollw, 1000); //setInterval定时器，当页面加载完执行定时器




    
  },
};
</script>


<style scoped>
@media screen and (min-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }
  .center {
    width: 102%;
    margin-top: 1%;
    /* background-color: aqua; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 27%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_center {
    width: 44%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_right {
    width: 27%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_left_1 {
    width: 100%;
    height: 28%;
    /* background-color: brown; */
  }
  .center_left_2 {
    width: 100%;
    height: 32%;
    /* background-color: brown; */
  }
  .center_left_3 {
    width: 100%;
    height: 39%;
    /* background-color: brown; */
  }
  .bj1 {
    background: url(../../../public/image/bj1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj2 {
    background: url(../../../public/image/bj2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj3 {
    background: url(../../../public/image/bj3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj4 {
    background: url(../../../public/image/bj4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cen_tit {
    width: 100%;
    height: 12%;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cen_zt {
    width: 90%;
    height: 80%;
    /* background-color: aquamarine; */
    display: flex;
  }
  .cen_zt_1 {
    width: 40%;
    height: 100%;
    /* background-color: burlywood; */
  }
  .cen_zt_2 {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .cen_zt_2_1 {
    width: 80%;
    height: 15%;
    background-color: brown;
    background: url(../../../public/image/xinywenz.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
  }
  .cen_zt_2_2 {
    width: 100%;
    height: 70%;
    /* background-color: azure; */
    color: #ffffff;
  }
  #box {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
  }
  .anim {
    transition: all 0.5s;
  }
  #con1 li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  .ztmllb{
    width: 100%;
    height: 165px;
    margin-top: 5px;
    background: url(../../../public/image/ztmlbj.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction:row;
    font-size: 12px;
    color: #ffffff;
  }




  #boxw {
  margin: 0 auto;
    width: 90%;
    height: 90%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
}
.animw {
  transition: all 0.5s;
}
#con1w li {
  list-style: none;
  line-height: 30px;
  height: 30px;
}
.ztmllw{
  width: 100%;
    height: 50px;
    margin-top: 10px;

    display: flex;
    flex-direction:row;
    font-size: 12px;
    color: #ffffff;

}
.jiandusi{
  width: 23%; height: 100%;
  background-color: #0a1f5d;
  color: #ffffff;
  font-size: 14px;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  

}
.bjmapll{
      position: absolute;
    top:10%;
    left: 8%;
    color: #ffffff;
    font-size: 17px;
  

}
.pmk{
  width: 90%;height: 5.7%;    border-bottom: 1px dashed #006273;    display: flex;justify-content: center;color:#ffffff;

}
.pmk_nr{
  width: 25%;height: 100%;    display: flex;align-items: flex-end;justify-content: center;font-size: 13px;
}
}

/* 小于1400px */
@media screen and (max-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }

  .center {
    width: 102%;
    /* background-color: aqua; */
    /* margin-top: 1%; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 27%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_center {
    width: 44%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_right {
    width: 27%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_left_1 {
    width: 100%;
    height: 25%;
    /* background-color: brown; */
  }
  .center_left_2 {
    width: 100%;
    height: 25%;
    /* background-color: brown; */
  }
  .center_left_3 {
    width: 100%;
    height: 40%;
    /* background-color: brown; */
  }
  .bj1 {
    background: url(../../../public/image/bj1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bj2 {
    background: url(../../../public/image/bj2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj3 {
    background: url(../../../public/image/bj3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bj4 {
    background: url(../../../public/image/bj4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_tit {
    width: 100%;
    height: 12%;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cen_zt {
    width: 90%;
    height: 80%;
    /* background-color: aquamarine; */
    display: flex;
  }
  .cen_zt_1 {
    width: 40%;
    height: 100%;
    /* background-color: burlywood; */
  }
  .cen_zt_2 {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .cen_zt_2_1 {
    width: 80%;
    height: 15%;
    background-color: brown;
    background: url(../../../public/image/xinywenz.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
  }
  .cen_zt_2_2 {
    width: 100%;
    height: 70%;
    /* background-color: azure; */
    color: #ffffff;
  }
  #box {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
  }
  .anim {
    transition: all 0.5s;
  }
  #con1 li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  .ztmllb{
    width: 100%;
    height: 165px;
    margin-top: 5px;
    background: url(../../../public/image/ztmlbj.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction:row;
    font-size: 12px;
    color: #ffffff;
  }




  #boxw {
  margin: 0 auto;
    width: 90%;
    height: 90%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
}
.animw {
  transition: all 0.5s;
}
#con1w li {
  list-style: none;
  line-height: 30px;
  height: 30px;
}
.ztmllw{
  width: 100%;
    height: 50px;
    margin-top: 10px;

    display: flex;
    flex-direction:row;
    font-size: 12px;
    color: #ffffff;

}
.jiandusi{
  width: 23%; height: 100%;
  background-color: #0a1f5d;
  color: #ffffff;
  font-size: 14px;
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



}
.bjmapll{
   position: absolute;
    top: 7%;
    left: 5%;
    color: #ffffff;
    font-size: 15px;

}
.pmk{
  width: 90%;height: 5.7%;    border-bottom: 1px dashed #006273;    display: flex;justify-content: center;color:#ffffff;

}
.pmk_nr{
  width: 25%;height: 100%;    display: flex;align-items: flex-end;justify-content: center;font-size: 12px;
}
.pmyu{
  width: 35%!important;

}
}
</style>









