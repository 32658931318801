import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// echarts
// 引入echarts
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts





import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'eNry5NgS1y86oDazDVGYRAPyUS2acsGN'
})



import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios) 
Vue.prototype.$ajax=axios
Vue.prototype.$http = axios




// import { VueAwesomeSwiper } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
// Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
