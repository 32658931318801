<template>
  <div class="home">
    <div class="center">
      <div class="center_left">
        <div class="center_left_1 bj">
          <div class="cen_tit">企业信息</div>

          <form action="">
            <Handleindexelel
              :selectData="selectData"
              :selValue="selValue"
              color="white"
              @getValue="getValue"
            />

            <!-- <div class="ddpdwn">
              <select @click="handlexz()" v-model="this.valuell">
                <option class="option" :value="0" >安徽裕旺农业科技发展有限公司</option>
                <option class="option" :value="1" >安徽裕旺农业科技发展有限公司1</option>
                <option class="option" :value="2" >安徽裕旺农业科技发展有限公司2</option>
                <option class="option" :value="3" >安徽裕旺农业科技发展有限公司3</option>
                <option class="option" :value="4" >安徽裕旺农业科技发展有限公司4</option>
              </select>
            </div> -->
          </form>

          <div
            id="xxxs"
            style="
              width: 90%;
              height: 80%;
              margin-top: 3%;
              font-size: 15px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: flex-start;
              color: #8f94aa;
              position: relative;
            "
          >
            <div v-for="(item, index) in itemsxx" :key="index">
              <div
                style="
                  color: #17caf0;
                  font-size: 20px;
                  display: flex;
                  justify-content: flex-start;
                "
              >
                {{ item.name }}
              </div>
              <div style="display: flex; justify-content: flex-start">
                联系人：{{ item.namell }}
              </div>
              <div style="display: flex; justify-content: flex-start">
                联系电话：{{ item.tel }}
              </div>
              <div style="display: flex; justify-content: flex-start">
                规模：100.00亩
              </div>
              <div style="display: flex; justify-content: flex-start">
                企业类型：水果
              </div>
              <div style="display: flex; justify-content: flex-start">
                主题类型：农产品企业
              </div>
              <div style="display: flex; justify-content: flex-start;    position: absolute;bottom: 0;right: 0;color: red;">
                信用分值：{{item.xyfz}}
              </div>
              <div style="display: flex; justify-content: flex-start">
                合格证开具数量：{{item.hgzkj}}个
              </div>
            </div>

            <div
              style="
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
                height: 80%;
              "
            >
              <div
                id="bar_chart666"
                class="chart"
                style="width: 100%; height: 100%"
              ></div>
            </div>

            

            
          </div>
        </div>
        <div class="center_left_2 bj1">
          <div class="cen_tit">种植计划</div>
          <div
            style="
              height: 80%;
              width: 90%;
              margin-top: 3%;
              overflow-x: hidden;
              overflow-y: scroll;
            "
            id="demo"
          >
            <div
              style="
                height: 50px;
                width: 100%;
                border-bottom: 1px dashed #006273;
                font-size: 12px;
              "
            >
              <div
                style="height: 50%; width: 100%; display: flex; color: #ffffff"
              >
                {{this.itemswzzname1}}
              </div>
              <div
                style="
                  height: 50%;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: #107bba;
                "
              >
                {{this.itemswzznamell1}}

                

                <div
                  id="bar_chartt11"
                  class="chart"
                  style="height: 60%; width: 80%"
                ></div>

                <span style="color: red">进行中</span>
              </div>
            </div>

            <div
              style="
                height: 50px;
                width: 100%;
                border-bottom: 1px dashed #006273;
                font-size: 12px;
              "
            >
              <div
                style="height: 50%; width: 100%; display: flex; color: #ffffff"
              >
                {{this.itemswzzname2}}
              </div>
              <div
                style="
                  height: 50%;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: #107bba;
                "
              >
                {{this.itemswzznamell2}}
                <div
                  id="bar_chartt12"
                  class="chart"
                  style="height: 60%; width: 80%"
                ></div>
                <span style="color: red">进行中</span>
              </div>
            </div>

            <div
              style="
                height: 50px;
                width: 100%;
                border-bottom: 1px dashed #006273;
                font-size: 12px;
              "
            >
              <div
                style="height: 50%; width: 100%; display: flex; color: #ffffff"
              >
                {{this.itemswzzname3}}
              </div>
              <div
                style="
                  height: 50%;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: #107bba;
                "
              >
                {{this.itemswzznamell3}}
                <div
                  id="bar_chartt13"
                  class="chart"
                  style="height: 60%; width: 80%"
                ></div>
                <span style="color: red">进行中</span>
              </div>
            </div>

            <div
              style="
                height: 50px;
                width: 100%;
                border-bottom: 1px dashed #006273;
                font-size: 12px;
              "
            >
              <div
                style="height: 50%; width: 100%; display: flex; color: #ffffff"
              >
                {{this.itemswzzname4}}
              </div>
              <div
                style="
                  height: 50%;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: #107bba;
                "
              >
                {{this.itemswzznamell4}}
                <div
                  id="bar_chartt14"
                  class="chart"
                  style="height: 60%; width: 80%"
                ></div>
                <span style="color: red">进行中</span>
              </div>
            </div>

            <div
              style="
                height: 50px;
                width: 100%;
                border-bottom: 1px dashed #006273;
                font-size: 12px;
              "
            >
              <div
                style="height: 50%; width: 100%; display: flex; color: #ffffff"
              >
                {{this.itemswzzname5}}
              </div>
              <div
                style="
                  height: 50%;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: #107bba;
                "
              >
                {{this.itemswzznamell5}}
                <div
                  id="bar_chartt15"
                  class="chart"
                  style="height: 60%; width: 80%"
                ></div>
                <span style="color: red">进行中</span>
              </div>
            </div>

            <!-- <div style=" height: 50px;width: 100%;border-bottom:1px dashed #006273;font-size:12px">
              <div style=" height: 50%;width: 100%;    display: flex;color:#ffffff">20211126,红颜草莓，情园农场</div>
              <div style=" height: 50%;width: 100%;display: flex;justify-content: space-between;color:#107bba;">红颜草莓<Handleindexshansuo style="height: 60%;width: 80%;"></Handleindexshansuo><span style="color:red;">进行中</span></div>
              

            </div> -->
          </div>



          


        </div>
        <div class="center_left_3 bj2">
          <div class="cen_tit">合格证开具</div>

          <div
            style="height: 80%; width: 90%; margin-top: 3%; position: relative"
          >
            <!-- vue 实现无限向上滚动 -->
            <div id="boxwws">
              <div
                id="con1wws"
                ref="con1wws"
                :class="{ animwws: animatewws == true }"
                @mouseenter="mEnterwws"
                @mouseleave="mLeavewws"
              >
                <div
                  v-for="(item, index) in itemswws"
                  :key="index"
                  style="
                    border-bottom: 1px dashed #006273;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 10%;
                    padding-right: 10%;
                  "
                  class="ppp"
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: red;
                    "
                  >
                    <span>{{ item.name }}</span>
                  </div>
                  <div style="color: #ea9777">开具了{{ item.num }}张合格证</div>

                  <div style="color: #11b999">{{ item.datetime }}</div>
                </div>
              </div>
            </div>
          </div>



          
        </div>
      </div>
      <div class="center_center">
        <div
          class="center_left_1 bj3"
          style="
            height: 61%;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div class="cen_tit" style="height: 6%">草莓标签和扫码页面</div>

          <div class="cmbq">
            <div
              style="
                height: 100%;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                style="
                  height: 40%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="../../../public/image/cmbq14.png"
                  alt=""
                  style="height: 70%"
                />
                <img
                  src="../../../public/image/cmbq15.png"
                  alt=""
                  style="height: 70%"
                />
              </div>
              <div
                style="
                  height: 40%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  src="../../../public/image/cmbq13.png"
                  alt=""
                  style="width: 50%"
                />
              </div>
            </div>
            <div
              style="
                height: 100%;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div>
                <img
                  src="../../../public/image/cmbbq11.png"
                  alt=""
                  style="height: 80%; width: 90%"
                />
              </div>
              <div>
                <img
                  src="../../../public/image/cmbq12.png"
                  alt=""
                  style="height: 80%; width: 90%"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="center_left_1"
          style="height: 38%; display: flex; justify-content: space-between"
        >
          <div
            style="
              height: 100%;
              width: 49%;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
            class="bj4"
          >
            <div class="cen_tit">物联网数据</div>

            <div
              style="width: 90%; height: 70%; margin-top: 9%; margin-right: 2%"
            >
              <div style="width: 100%; height: 50%; display: flex">
                <div style="width: 50%; height: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <img
                      src="/image/kqwd.png"
                      alt=""
                      style="width: 65px; height: 60px"
                    />
                  </div>

                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 12px;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        height: 20%;
                        color: #0195c2;
                        font-size: 18px;
                      "
                    >
                      <b>16.1℃</b>
                    </div>
                    <div style="width: 100%; height: 20%; color: #ffffff">
                      空气温度
                    </div>
                  </div>
                </div>
                <div style="width: 50%; height: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <img
                      src="/image/kqsd.png"
                      alt=""
                      style="width: 65px; height: 60px"
                    />
                  </div>

                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 12px;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        height: 20%;
                        color: #0195c2;
                        font-size: 18px;
                      "
                    >
                      <b>77.3%RH</b>
                    </div>
                    <div style="width: 100%; height: 20%; color: #ffffff">
                      空气湿度
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 50%; display: flex">
                <div style="width: 50%; height: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <img
                      src="/image/tewd.png"
                      alt=""
                      style="width: 65px; height: 60px"
                    />
                  </div>

                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 12px;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        height: 20%;
                        color: #0195c2;
                        font-size: 18px;
                      "
                    >
                      <b>17.3℃</b>
                    </div>
                    <div style="width: 100%; height: 20%; color: #ffffff">
                      土壤温度
                    </div>
                  </div>
                </div>
                <div style="width: 50%; height: 100%; display: flex">
                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                    "
                  >
                    <img
                      src="/image/gzqd.png"
                      alt=""
                      style="width: 65px; height: 60px"
                    />
                  </div>

                  <div
                    style="
                      width: 50%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      font-size: 12px;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        height: 20%;
                        color: #0195c2;
                        font-size: 18px;
                      "
                    >
                      <b>16318Lux</b>
                    </div>
                    <div style="width: 100%; height: 20%; color: #ffffff">
                      光照强度
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              height: 100%;
              width: 49%;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
            "
            class="bj4"
          >
            <div class="cen_tit">视频监控</div>

            <div style="width: 90%; height: 71%; margin-top: 13%">
              <video
                src="https://static.zcool.cn/v45.10.19.06-v18-07-24-16/special-resource/time-lapse//video/video.mp4"
                style="width: 100%"
              ></video>
            </div>
          </div>
        </div>
      </div>
      <div class="center_right">
        <div
          class="center_left_1 bj"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <div class="cen_tit">最新农事操作</div>

          <div style="height: 80%; width: 90%; margin-top: 3%">
            <!-- vue 实现无限向上滚动 -->
            <div id="boxw">
              <div
                id="con1w"
                ref="con1w"
                :class="{ animw: animatew == true }"
                @mouseenter="mEnterw"
                @mouseleave="mLeavew"
              >
                <div
                  v-for="(item, index) in itemsw"
                  :key="index"
                  style="
                    border-bottom: 1px dashed #006273;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                    "
                  >
                    {{ item.product_life_name }}
                  </div>
                  <div
                    style="
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                    "
                  >
                    {{ item.opion }}
                  </div>
                  <div
                    style="
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                    "
                  >
                    {{ item.person }}
                  </div>
                  <div
                    style="
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                    "
                  >
                    {{ item.datetime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center_left_2 bj1">
          <div class="cen_tit">追溯查询记录</div>
          <div
            style="
              height: 80%;
              width: 90%;
              margin-top: 3%;
              position: absolute;
              bottom: 3%;
            "
          >
            <!-- vue 实现无限向上滚动 -->
            <div id="boxww">
              <div
                id="con1ww"
                ref="con1ww"
                :class="{ animww: animateww == true }"
                @mouseenter="mEnterww"
                @mouseleave="mLeaveww"
              >
                <div
                  v-for="(item, index) in itemsww"
                  :key="index"
                  style="
                    border-bottom: 1px dashed #006273;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      height: 60%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: red;
                      border-radius: 30px;
                    "
                  >
                    {{ item.address }}
                  </div>
                  <div
                    style="
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    {{ item.code }}
                  </div>
                  <div
                    style="
                      colr: #00fcf9;
                      height: 100%;
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    查看
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center_left_3 bj2">
          <div class="cen_tit">最新投入品使用</div>
          <div
            style="height: 80%; width: 90%; margin-top: 3%; position: relative"
          >
            <!-- vue 实现无限向上滚动 -->
            <div
              id="boxwwss"
              style="
                height: 96%;
                margin-top: 3%;
                overflow: hidden scroll;
                color: #ffffff;
                font-size: 12px;
              "
            >
              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="/image/one.png"
                      alt=""
                      style="height: 20px; width: 20px"
                    />
                  </div>
                  <div style="color: red; width: 63%">{{this.itemstrpname1}}</div>
                </div>
                <div>{{this.itemstrpnum1}} {{this.itemstrpmark1}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="/image/two.png"
                      alt=""
                      style="height: 20px; width: 20px"
                    />
                  </div>
                  <div style="color: #ff9b6a; width: 63%">{{this.itemstrpname2}}</div>
                </div>
                <div>{{this.itemstrpnum2}} {{this.itemstrpmark2}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="/image/three.png"
                      alt=""
                      style="height: 20px; width: 20px"
                    />
                  </div>
                  <div style="color: #ffd074; width: 63%">{{this.itemstrpname3}}</div>
                </div>
                <div>{{this.itemstrpnum3}} {{this.itemstrpmark3}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #00fcf9;
                      background: url(/image/dbx.png);
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      height: 20px;
                    "
                  >
                    <span style="">4</span>
                  </div>
                  <div style="color: 00fcf9; width: 63%; color: #00fcf9">
                    {{this.itemstrpname4}}
                  </div>
                </div>
                <div>{{this.itemstrpnum4}} {{this.itemstrpmark4}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #00fcf9;
                      background: url(/image/dbx.png);
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      height: 20px;
                    "
                  >
                    <span>5</span>
                  </div>
                  <div style="color: 00fcf9; width: 63%; color: #00fcf9">
                    {{this.itemstrpname5}}
                  </div>
                </div>
                <div>{{this.itemstrpnum5}} {{this.itemstrpmark5}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #00fcf9;
                      background: url(/image/dbx.png);
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      height: 20px;
                    "
                  >
                    <span>6</span>
                  </div>
                  <div style="color: 00fcf9; width: 63%; color: #00fcf9">
                    {{this.itemstrpname6}}
                  </div>
                </div>
                <div>{{this.itemstrpnum6}} {{this.itemstrpmark6}}</div>
              </div>

              <div
                style="
                  border-bottom: 1px dashed #006273;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-left: 10%;
                  padding-right: 10%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 45%;
                    justify-content: space-around;
                  "
                >
                  <div
                    style="
                      width: 20%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #00fcf9;
                      background: url(/image/dbx.png);
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      height: 20px;
                    "
                  >
                    <span>7</span>
                  </div>
                  <div style="color: 00fcf9; width: 63%; color: #00fcf9">
                    {{this.itemstrpname7}}
                  </div>
                </div>
                <div>{{this.itemstrpnum7}} {{this.itemstrpmark7}}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Handleindexshansuo from "@/components/Handleindexshansuo.vue";

import Handleindexelel from "@/components/Handleindexelel.vue";

import cfindex from "@/views/cfindex.vue";

export default {
  name: "handleindex",

  components: {
    Handleindexshansuo,
    cfindex,
    Handleindexelel,
  },
  data() {
    return {
      indicator: [
        {
          name: "特殊人群",
          max: 100,
        },
        {
          name: "信访",
          max: 100,
        },
        {
          name: "还是说",
          max: 100,
        },
        {
          name: "事件",
          max: 100,
        },
        {
          name: "矛盾调解",
          max: 100,
        },
      ],

      indicator1: [
        {
          name: "特殊人群",
          max: 200,
        },
        {
          name: "信访",
          max: 100,
        },
        {
          name: "还是说",
          max: 100,
        },
        {
          name: "事件",
          max: 100,
        },
        {
          name: "矛盾调解",
          max: 100,
        },
      ],

      selectData: [
        {
          name: "合肥市艳九天农业科技有限公司",
          value: 1,
        },
        {
          name: "安徽归郢农业科技有限公司",
          value: 2,
        },
        
      ],
      selValue: "",

      valuell: 0,
      animatew: false,
      itemsw: [
        //消息列表对应的数组
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
        {
          name: "第三茬果",
          yf: "辅助授粉",
          namell: "孙阳阳",
          time: "2022-03-16",
        },
      ],


      itemswzz: [],

      itemswzzname1:"",
      itemswzzname2:"",
      itemswzzname3:"",
      itemswzzname4:"",
      itemswzzname5:"",
      itemswzznamell1:"",
      itemswzznamell2:"",
      itemswzznamell3:"",
      itemswzznamell4:"",
      itemswzznamell5:"",

      animateww: false,
      itemsww: [
        //消息列表对应的数组
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
        { sm: "安徽省", zsmh: "C21122409150167250" },
      ],


      animatewws: false,
      itemswws: [
        //消息列表对应的数组
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "50", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
        { name: "49", num: "苹果", zh: "03-11" },
      ],
      itemstrp:[],

      itemstrpname1:"",
      itemstrpname2:"",
      itemstrpname3:"",
      itemstrpname4:"",
      itemstrpname5:"",
      itemstrpname6:"",
      itemstrpname7:"",

      itemstrpnum1:"",
      itemstrpnum2:"",
      itemstrpnum3:"",
      itemstrpnum4:"",
      itemstrpnum5:"",
      itemstrpnum6:"",
      itemstrpnum7:"",



      itemstrpmark1:"",
      itemstrpmark2:"",
      itemstrpmark3:"",
      itemstrpmark4:"",
      itemstrpmark5:"",
      itemstrpmark6:"",
      itemstrpmark7:"",


      itemsxx: [
        //消息列表对应的数组
        {
          name: "合肥市艳九天农业科技有限公司",
          namell: "沈海燕",
          gm: "122亩",
          tel: "18955139933",
          qylx: "水果",
          ztlx: "农产品企业",
          xyfz: "86A",
          hgzkj: "33",
        },
        
      ],
    };
  },

  mounted() {
    this.initEcharts();
    
    this.timer1w = setInterval(this.scrollw, 1000); //setInterval定时器，当页面加载完执行定时器
    this.timer1ww = setInterval(this.scrollww, 1500); //setInterval定时器，当页面加载完执行定时器
    this.timer1wws = setInterval(this.scrollwws, 1000); //setInterval定时器，当页面加载完执行定时器
  },

  methods: {
    getValue(name, value, index) {
      // console.log("item:", name, value, index);
       console.log( value);

      if (value == 1) {
        console.log("1111111111")
         console.log(cfindex.methods)
        console.log(cfindex.methods.handlelildqyzt1())
        




      }
      if (value == 2) {
        console.log("2222222222222")
         console.log(cfindex.methods)
        console.log(cfindex.methods.handlelildqyzt2())
       
          
         



      }
      if (value == 3) {
        

        


      }
      if (value == 4) {
        


        


      }
      if (value == 5) {
        



        
      }
    },

    initEcharts() {
      var ii=0;
      var yy=0;
      var kk=0;
      var iii=0;
      var _this=this

     

      this.$http.get(ppppl + "/jee5/verson/subject/list?id=209a3d74d62946be87f536c2623c3194").then((res) => {
         console.log(res.data.result)


          _this.itemsxx[0].xyfz=res.data.result[36].ihgv
        _this.itemsxx[0].xyfz=_this.itemsxx[0].xyfz+res.data.result[36].jis
        _this.itemsxx[0].hgzkj=res.data.result[36].hgzm



          // this.items = res.data.result;
          //  console.log(this.items[0])
          for(var o=0;o<=9;o++){
            this.itemsw[o]=res.data.result[o]

            // itemsww
            

          }
          for(var tt=10;tt<=19;tt++){
            this.itemsww[ii]=res.data.result[tt]
            ii=ii+1

            
            

          }

          for(var ttt=20;ttt<=25;ttt++){
            this.itemswzz[iii]=res.data.result[ttt]
            if(iii==0){
              this.itemswzzname1=this.itemswzz[0].name
              this.itemswzznamell1=this.itemswzz[0].modal_product_name

            }
            if(iii==1){
              this.itemswzzname2=this.itemswzz[1].name
              this.itemswzznamell2=this.itemswzz[1].modal_product_name

            }
            if(iii==2){
              this.itemswzzname3=this.itemswzz[2].name
              this.itemswzznamell3=this.itemswzz[2].modal_product_name

            }
            if(iii==3){
              this.itemswzzname4=this.itemswzz[3].name
              this.itemswzznamell4=this.itemswzz[3].modal_product_name

            }
            if(iii==4){
              this.itemswzzname5=this.itemswzz[4].name
              this.itemswzznamell5=this.itemswzz[4].modal_product_name

            }
            
            
            
            
            iii=iii+1

          
          

        }

        for(var jj=26;jj<=35;jj++){
          this.itemswws[yy]=res.data.result[jj]
          yy=yy+1

          console.log(res.data.result[yy])

          
          

        }


        for(var ww=37;ww<=44;ww++){
          this.itemstrp[kk]=res.data.result[ww]




          if(kk==0){
            this.itemstrpname1=this.itemstrp[0].name
            this.itemstrpnum1=this.itemstrp[0].num
            console.log(this.itemstrp[0].mark)

            if(this.itemstrp[0].mark==""){
              this.itemstrpmark1="斤"

            }
            else{
              this.itemstrpmark1=this.itemstrp[0].mark
              
            }
            
            

          }
          if(kk==1){
            this.itemstrpname2=this.itemstrp[1].name
            this.itemstrpnum2=this.itemstrp[1].num

            if(this.itemstrp[1].mark==""){
              this.itemstrpmark1="斤"

            }
            else{

              this.itemstrpmark2=this.itemstrp[1].mark

            }
            

          }
          if(kk==2){
            this.itemstrpname3=this.itemstrp[2].name
            this.itemstrpnum3=this.itemstrp[2].num



            if(this.itemstrp[2].mark==""){
               this.itemstrpmark3="斤"

            }
            else{

              this.itemstrpmark3=this.itemstrp[2].mark

            }


            

          }
          if(kk==3){
            this.itemstrpname4=this.itemstrp[3].name
            this.itemstrpnum4=this.itemstrp[3].num


            if(this.itemstrp[3].mark==""){
               this.itemstrpmark4="斤"

            }
            else{

              this.itemstrpmark4=this.itemstrp[3].mark

            }
            

          }
          if(kk==4){
            this.itemstrpname5=this.itemstrp[4].name
            this.itemstrpnum5=this.itemstrp[4].num
            this.itemstrpmark5=this.itemstrp[4].mark


            if(this.itemstrp[4].mark==""){
               this.itemstrpmark5="斤"

            }
            else{

              this.itemstrpmark5=this.itemstrp[4].mark

            }


          }
          if(kk==6){
            this.itemstrpname6=this.itemstrp[5].name
            this.itemstrpnum6=this.itemstrp[5].num
            this.itemstrpmark6=this.itemstrp[5].mark

            if(this.itemstrp[5].mark==""){
               this.itemstrpmark6="斤"

            }
            else{

              this.itemstrpmark6=this.itemstrp[5].mark

            }


            

          }
          if(kk==7){
            // console.log("11111111111")
            this.itemstrpname7=this.itemstrp[6].name
            this.itemstrpnum7=this.itemstrp[6].num
            


            if(this.itemstrp[6].mark==""){
                this.itemstrpmark7="斤"

            }
            else{

              this.itemstrpmark7=this.itemstrp[6].mark

            }

            

          }



          kk=kk+1





          // console.log(res.data.result[kk])

          
          

        }
        console.log(this.itemswws)

        


        



        



      });







      

      //执法
      // let backgroundColor = 'rgba(0,0,0,1)';
      let chartData = [[91], ["BBB"]];
      let getmydmc = chartData[1]; //数据点名称
      let getmyd = chartData[0]; //收入金额
      let getmydzd = [];

      let big = 0;
      getmyd.forEach((el) => {
        if (!(el === undefined || el === "" || el === 0)) {
          if (big < Number(el)) {
            big = Number(el);
          }
        } else {
          big = 100;
        }
      });
      for (let i = 0; i < getmyd.length; i++) {
        getmydzd.push(big * 4);
      }
      //计算最大值
      function calMax(arr) {
        let max = 0;
        arr.forEach((el) => {
          el.forEach((el1) => {
            if (!(el1 === undefined || el1 === "" || el1 === 0)) {
              if (max < Number(el1)) {
                max = Number(el1);
              }
            } else {
              max == 100;
            }
          });
        });
        let maxint = Math.ceil(max / 9.5);
        //不让最高的值超过最上面的刻度
        let maxval = maxint * 10;
        //让显示的刻度是整数
        return maxval;
      }

      const max = Math.ceil(calMax([getmyd]) / 10) * 10;

      var optiond = {
        // backgroundColor: '#001037',
        grid: {
          top: "10%",
          left: "5%",
          right: "2%",
          bottom: "14%",
        },
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(65,114,231,0.8)",
          formatter: function (item) {
            return (
              item[0].axisValueLabel +
              "<br />" +
              item[0].seriesName +
              ": " +
              item[0].data
            );
          },
        },
        yAxis: {
          show: false,
          data: ["1月"],
          axisLine: {
            lineStyle: {
              color: "#0a4980",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#ADD6FF",
              fontSize: 12,
            },
          },
        },
        xAxis: [
          {
            show: false,
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#0a4980",
              },
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: "#ADD6FF",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(5,27,56)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(36,188,255)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [90],
            z: 0,
            zlevel: 0,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
            },
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#011140",
              },
            },
            symbolRepeat: 11,
            // symbolMargin: 300,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [4, 15],
            symbolPosition: "start",
            symbolOffset: [0, 0],
            data: [100],
            z: 1,
            zlevel: 0,
          },
          {
            name: "机器故障率",
            type: "bar",
            barGap: "-125%",
            data: [100],
            barWidth: 18,
            itemStyle: {
              normal: {
                color: "transparent",
                barBorderColor: "rgb(148,217,249)",
                barBorderWidth: 1,
              },
            },
            z: 2,
          },
        ],
      };

      echarts.init(document.getElementById("bar_chartt11")).setOption(optiond);

      var optiond2 = {
        // backgroundColor: '#001037',
        grid: {
          top: "10%",
          left: "5%",
          right: "2%",
          bottom: "14%",
        },
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(65,114,231,0.8)",
          formatter: function (item) {
            return (
              item[0].axisValueLabel +
              "<br />" +
              item[0].seriesName +
              ": " +
              item[0].data
            );
          },
        },
        yAxis: {
          show: false,
          data: ["1月"],
          axisLine: {
            lineStyle: {
              color: "#0a4980",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#ADD6FF",
              fontSize: 12,
            },
          },
        },
        xAxis: [
          {
            show: false,
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#0a4980",
              },
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: "#ADD6FF",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(5,27,56)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(36,188,255)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [80],
            z: 0,
            zlevel: 0,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
            },
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#011140",
              },
            },
            symbolRepeat: 11,
            // symbolMargin: 300,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [4, 15],
            symbolPosition: "start",
            symbolOffset: [0, 0],
            data: [100],
            z: 1,
            zlevel: 0,
          },
          {
            name: "机器故障率",
            type: "bar",
            barGap: "-125%",
            data: [100],
            barWidth: 18,
            itemStyle: {
              normal: {
                color: "transparent",
                barBorderColor: "rgb(148,217,249)",
                barBorderWidth: 1,
              },
            },
            z: 2,
          },
        ],
      };

      echarts.init(document.getElementById("bar_chartt12")).setOption(optiond2);

      var optiond3 = {
        // backgroundColor: '#001037',
        grid: {
          top: "10%",
          left: "5%",
          right: "2%",
          bottom: "14%",
        },
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(65,114,231,0.8)",
          formatter: function (item) {
            return (
              item[0].axisValueLabel +
              "<br />" +
              item[0].seriesName +
              ": " +
              item[0].data
            );
          },
        },
        yAxis: {
          show: false,
          data: ["1月"],
          axisLine: {
            lineStyle: {
              color: "#0a4980",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#ADD6FF",
              fontSize: 12,
            },
          },
        },
        xAxis: [
          {
            show: false,
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#0a4980",
              },
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: "#ADD6FF",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(5,27,56)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(36,188,255)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [90],
            z: 0,
            zlevel: 0,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
            },
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#011140",
              },
            },
            symbolRepeat: 11,
            // symbolMargin: 300,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [4, 15],
            symbolPosition: "start",
            symbolOffset: [0, 0],
            data: [100],
            z: 1,
            zlevel: 0,
          },
          {
            name: "机器故障率",
            type: "bar",
            barGap: "-125%",
            data: [100],
            barWidth: 18,
            itemStyle: {
              normal: {
                color: "transparent",
                barBorderColor: "rgb(148,217,249)",
                barBorderWidth: 1,
              },
            },
            z: 2,
          },
        ],
      };

      echarts.init(document.getElementById("bar_chartt13")).setOption(optiond3);

      var optiond4 = {
        // backgroundColor: '#001037',
        grid: {
          top: "10%",
          left: "5%",
          right: "2%",
          bottom: "14%",
        },
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(65,114,231,0.8)",
          formatter: function (item) {
            return (
              item[0].axisValueLabel +
              "<br />" +
              item[0].seriesName +
              ": " +
              item[0].data
            );
          },
        },
        yAxis: {
          show: false,
          data: ["1月"],
          axisLine: {
            lineStyle: {
              color: "#0a4980",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#ADD6FF",
              fontSize: 12,
            },
          },
        },
        xAxis: [
          {
            show: false,
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#0a4980",
              },
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: "#ADD6FF",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(5,27,56)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(36,188,255)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [80],
            z: 0,
            zlevel: 0,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
            },
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#011140",
              },
            },
            symbolRepeat: 11,
            // symbolMargin: 300,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [4, 15],
            symbolPosition: "start",
            symbolOffset: [0, 0],
            data: [100],
            z: 1,
            zlevel: 0,
          },
          {
            name: "机器故障率",
            type: "bar",
            barGap: "-125%",
            data: [100],
            barWidth: 18,
            itemStyle: {
              normal: {
                color: "transparent",
                barBorderColor: "rgb(148,217,249)",
                barBorderWidth: 1,
              },
            },
            z: 2,
          },
        ],
      };

      echarts.init(document.getElementById("bar_chartt14")).setOption(optiond4);

      var optiond5 = {
        // backgroundColor: '#001037',
        grid: {
          top: "10%",
          left: "5%",
          right: "2%",
          bottom: "14%",
        },
        tooltip: {
          show: false,
          trigger: "axis",
          backgroundColor: "rgba(65,114,231,0.8)",
          formatter: function (item) {
            return (
              item[0].axisValueLabel +
              "<br />" +
              item[0].seriesName +
              ": " +
              item[0].data
            );
          },
        },
        yAxis: {
          show: false,
          data: ["1月"],
          axisLine: {
            lineStyle: {
              color: "#0a4980",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#ADD6FF",
              fontSize: 12,
            },
          },
        },
        xAxis: [
          {
            show: false,
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#0a4980",
              },
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              textStyle: {
                color: "#ADD6FF",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 12,
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgb(5,27,56)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgb(36,188,255)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [80],
            z: 0,
            zlevel: 0,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#ffffff",
                fontSize: "10",
              },
            },
          },
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#011140",
              },
            },
            symbolRepeat: 11,
            // symbolMargin: 300,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [4, 15],
            symbolPosition: "start",
            symbolOffset: [0, 0],
            data: [100],
            z: 1,
            zlevel: 0,
          },
          {
            name: "机器故障率",
            type: "bar",
            barGap: "-125%",
            data: [100],
            barWidth: 18,
            itemStyle: {
              normal: {
                color: "transparent",
                barBorderColor: "rgb(148,217,249)",
                barBorderWidth: 1,
              },
            },
            z: 2,
          },
        ],
      };

      echarts.init(document.getElementById("bar_chartt15")).setOption(optiond5);
















      var optiond6 = {
        //  backgroundColor: '#0D2753',
        tooltip: {
          show: false,
          //雷达图的tooltip不会超出div，也可以设置position属性，position定位的tooltip 不会随着鼠标移动而位置变化，不友好
          confine: true,
          enterable: true, //鼠标是否可以移动到tooltip区域内
        },
        radar: {
          nameGap: 2,
          name: {
            textStyle: {
              color: "#05D5FF",
              fontSize: 10,
            },
          },
          shape: "polygon",
          center: ["50%", "50%"],
          radius: "90%",
          startAngle: 120,
          scale: true,
          axisLine: {
            lineStyle: {
              color: "rgba(5, 213, 255, .8)",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "rgba(5, 213, 255, .8)", // 设置网格的颜色
            },
          },
          indicator: this.indicator,
          splitArea: {
            show: false,
          },
        },
        grid: {
          position: "center",
        },
        polar: {
          center: ["50%", "50%"], // 默认全局居中
          radius: "0%",
        },
        angleAxis: {
          min: 0,
          interval: 5,
          clockwise: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          min: 0,
          interval: 20,
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "个人雷达图",
            type: "radar",
            symbol: "circle", // 拐点的样式，还可以取值'rect','angle'等
            symbolSize: 5, // 拐点的大小
            itemStyle: {
              normal: {
                color: "#05D5FF",
              },
            },
            areaStyle: {
              normal: {
                color: "#05D5FF",
                opacity: 0.5,
              },
            },
            lineStyle: {
              width: 2,
              color: "#05D5FF",
            },
            label: {
              normal: {
                fontSize: 10,
                show: true,
                formatter: (params) => {
                  return params.value;
                },
                color: "#fff",
              },
            },
            data: [
              {
                value: [20, 50, 60, 60, 90, 80],
              },
            ],
          },
        ],
      };

      echarts.init(document.getElementById("bar_chart666")).setOption(optiond6);






      
    },

    scrollw() {
      //建一个方法
      var con1w = this.$refs.con1w;
      con1w.style.marginTop = "-41px"; //设置style样式 向上移动30px
      this.animatew = !this.animatew; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.itemsw.push(that.itemsw[0]); //尾部追加数组的第一个，放到数组最后
        that.itemsw.shift(); //删除第一个元素
        con1w.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animatew = !that.animatew; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnterw() {
      clearInterval(this.timer1w); //鼠标移入清除定时器
    },
    mLeavew() {
      this.timer1w = setInterval(this.scrollw, 2000); //鼠标离开启动定时器，执行 scroll
    },

    scrollww() {
      //建一个方法
      var con1ww = this.$refs.con1ww;
      con1ww.style.marginTop = "-41px"; //设置style样式 向上移动30px
      this.animateww = !this.animateww; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.itemsww.push(that.itemsww[0]); //尾部追加数组的第一个，放到数组最后
        that.itemsww.shift(); //删除第一个元素
        con1ww.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animateww = !that.animateww; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnterww() {
      clearInterval(this.timer1ww); //鼠标移入清除定时器
    },
    mLeaveww() {
      this.timer1ww = setInterval(this.scrollww, 1000); //鼠标离开启动定时器，执行 scroll
    },

    scrollwws() {
      //建一个方法
      var con1wws = this.$refs.con1wws;
      con1wws.style.marginTop = "-41px"; //设置style样式 向上移动30px
      this.animatewws = !this.animatewws; //
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.itemswws.push(that.itemswws[0]); //尾部追加数组的第一个，放到数组最后
        that.itemswws.shift(); //删除第一个元素
        con1wws.style.marginTop = "0px"; //设置style样式 向上移动30px 再回到原位
        that.animatewws = !that.animatewws; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
    mEnterwws() {
      clearInterval(this.timer1wws); //鼠标移入清除定时器
    },
    mLeavewws() {
      this.timer1wws = setInterval(this.scrollwws, 1000); //鼠标离开启动定时器，执行 scroll
    },
  },

  created() {
    // 初始化下拉框
    this.selValue = this.selectData[0].value;
  },
};
</script>


<style scoped>
@media screen and (min-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }
  .center {
    width: 102%;
    margin-top: 1%;
    /* background-color: aqua; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 26%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_center {
    width: 46%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_right {
    width: 26%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_left_1 {
    width: 100%;
    height: 30%;
    /* background-color: brown; */
  }
  .center_left_2 {
    width: 100%;
    height: 30%;
    /* background-color: brown; */
  }
  .center_left_3 {
    width: 100%;
    height: 38%;
    /* background-color: brown; */
  }
  .bj {
    background: url(../../../public/image/ztbj.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj1 {
    background: url(../../../public/image/ztbj1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .bj2 {
    background: url(../../../public/image/ztbj2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj3 {
    background: url(../../../public/image/ztbj3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bj4 {
    background: url(../../../public/image/ztbj4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_tit {
    width: 100%;
    height: 10%;
    display: flex;
    color: #00e4ff;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .cmbq {
    width: 90%;
    height: 90%;
    display: flex;
  }
  #boxw {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
  }
  .animw {
    transition: all 0.5s;
  }
  #con1w li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  #boxww {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
  }
  .animww {
    transition: all 0.5s;
  }
  #con1ww li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  #boxwws {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
    position: absolute;
  }
  .animwws {
    transition: all 0.5s;
  }
  #con1wws li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  /*滚动条区域*/

  #demo::-webkit-scrollbar,#demo2::-webkit-scrollbar,#demo3::-webkit-scrollbar,#demo4::-webkit-scrollbar,#demo5::-webkit-scrollbar {
    width: 10px;

    background-color: #031c6c;
  }

  /*滚动条*/

  #demo::-webkit-scrollbar-thumb,#demo2::-webkit-scrollbar-thumb,#demo3::-webkit-scrollbar-thumb,#demo4::-webkit-scrollbar-thumb,#demo5::-webkit-scrollbar-thumb {
    background-color: #082980;
    border-radius: 20px;
  }

  /*滚动条外层轨道*/

  #demo::-webkit-scrollbar-track,#demo2::-webkit-scrollbar-track,#demo3::-webkit-scrollbar-track,#demo4::-webkit-scrollbar-track,#demo5::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 1px ; */

    background-color: #031c6c;

    border-radius: 10px;
  }

  /*滚动条区域*/

  #boxwwss::-webkit-scrollbar {
    width: 10px;

    background-color: #031c6c;
  }

  /*滚动条*/

  #boxwwss::-webkit-scrollbar-thumb {
    background-color: #082980;
    border-radius: 20px;
  }

  /*滚动条外层轨道*/

  #boxwwss::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 1px ; */

    background-color: #031c6c;

    border-radius: 10px;
  }

  select {
    /* appearance: none; */
    outline: 0;
    background: linear-gradient(#031e69 0%, #024b96 100%);
    /* background-image: none; */
    width: 75px;
    height: 30px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #00b4ff;
    border-radius: 3px;
    padding: 2px;
  }

  .ddpdwn {
    /* position: relative; */
    display: block;
    line-height: 3;
    overflow: hidden;
    border-radius: 0.25em;
    padding-bottom: 10px;
    position: absolute;
    top: 15%;
    right: 5%;
    z-index: 999999999;
  }

  .option {
    background: #021884;
    color: #ffffff;
    border: none;
  }
  .ppp:hover {
    background: url(../../../public/image/hgzzx.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

/* 小于1400px */
@media screen and (max-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background: url(@/assets/bg.png) no-repeat; */
    /* background-size: 100% 100%; */
  }

  .center {
    width: 102%;
    /* background-color: aqua; */
    /* margin-top: 1%; */
    height: 100%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .center_left {
    width: 26%;
    height: 100%;
    /* background-color: rgb(231, 0, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_center {
    width: 46%;
    height: 100%;
    /* background-color: rgb(255, 0, 221); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_right {
    width: 26%;
    height: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .center_left_1 {
    width: 100%;
    height: 30%;
    /* background-color: brown; */
  }
  .center_left_2 {
    width: 100%;
    height: 30%;
    /* background-color: brown; */
  }
  .center_left_3 {
    width: 100%;
    height: 38%;
    /* background-color: brown; */
  }
  .bj {
    background: url(../../../public/image/ztbj.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj1 {
    background: url(../../../public/image/ztbj1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .bj2 {
    background: url(../../../public/image/ztbj2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bj3 {
    background: url(../../../public/image/ztbj3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .bj4 {
    background: url(../../../public/image/ztbj4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cen_tit {
    width: 100%;
    height: 10%;
    display: flex;
    color: #00e4ff;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .cmbq {
    width: 90%;
    height: 90%;
    display: flex;
  }
  #boxw {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
  }
  .animw {
    transition: all 0.5s;
  }
  #con1w li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  #boxww {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
  }
  .animww {
    transition: all 0.5s;
  }
  #con1ww li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  #boxwws {
    /* margin: 0 auto; */
    width: 100%;
    height: 100%;
    line-height: 30px;
    overflow: hidden;
    /* padding-left: 30px; */
    /* border: 1px solid #ffffff; */
    transition: all 0.5s;
    color: #ffffff;
    font-size: 12px;
    position: absolute;
  }
  .animwws {
    transition: all 0.5s;
  }
  #con1wws li {
    list-style: none;
    line-height: 30px;
    height: 30px;
  }

  /*滚动条区域*/

  #demo::-webkit-scrollbar {
    width: 10px;

    background-color: #031c6c;
  }

  /*滚动条*/

  #demo::-webkit-scrollbar-thumb {
    background-color: #082980;
    border-radius: 20px;
  }

  /*滚动条外层轨道*/

  #demo::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 1px ; */

    background-color: #031c6c;

    border-radius: 10px;
  }

  /*滚动条区域*/

  #boxwwss::-webkit-scrollbar {
    width: 10px;

    background-color: #031c6c;
  }

  /*滚动条*/

  #boxwwss::-webkit-scrollbar-thumb {
    background-color: #082980;
    border-radius: 20px;
  }

  /*滚动条外层轨道*/

  #boxwwss::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 1px ; */

    background-color: #031c6c;

    border-radius: 10px;
  }

  select {
    /* appearance: none; */
    outline: 0;
    background: linear-gradient(#031e69 0%, #024b96 100%);
    /* background-image: none; */
    width: 75px;
    height: 30px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #00b4ff;
    border-radius: 3px;
    padding: 2px;
  }

  .ddpdwn {
    /* position: relative; */
    display: block;
    line-height: 3;
    overflow: hidden;
    border-radius: 0.25em;
    padding-bottom: 10px;
    position: absolute;
    top: 10%;
    right: 5%;
    z-index: 999999999;
  }

  .option {
    background: #021884;
    color: #ffffff;
    border: none;
  }
  .ppp:hover {
    background: url(../../../public/image/hgzzx.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>









