<template>
  <div class="home">
    <div class="center">
      <div class="center_left">
        <img src="../../public/image/cfsmdsj.svg" alt="" style="width: 70%;height: 100%;margin-right: 13%;">
      </div>
      <div class="center_on">
        <div style="width: 24%;height: 73%;display: flex;align-items: center;justify-content: center;cursor:pointer;" @click="select = 1"
            :class="[select == 1 ? 'active' : 'top_left_on_img']">一张图</div>
        <div style="width: 24%;height: 73%;display: flex;align-items: center;justify-content: center;cursor:pointer;" @click="select = 2"
            :class="[select == 2 ? 'active' : 'top_left_on_img']">智慧监管</div>
        <div style="width: 24%;height: 73%;display: flex;align-items: center;justify-content: center;cursor:pointer;" @click="select = 3,overhhh()"
            :class="[select == 3 ? 'active' : 'top_left_on_img']" >企业主体</div>
        <div style="width: 24%;height: 73%;display: flex;align-items: center;justify-content: center;cursor:pointer;" @click="select = 4"
            :class="[select == 4 ? 'active' : 'top_left_on_img']">消费流通</div>
      </div>
      <div class="center_right">
        <img :src="'image/64/'+this.tupian+'.png'" alt="" style="width: 35px;height: 35px;">
        <div>{{wendu}}</div>
        <div>{{tianqi}}</div>
        <div>{{nowtimel}}</div>
        <div>{{nowWeek}}</div>
      </div>

    </div>
      <div class="centerll" v-if="select == 1" style="">
        <!-- <Handleindex></Handleindex> -->
        <HelloWorldyzt></HelloWorldyzt>
      </div>

      <div class="centerll" v-if="select == 2" style="">
        <HelloWorldzhjg></HelloWorldzhjg>
      </div>

      <div class="centerll" v-if="select == 3" style="" >
        <!-- <HelloWorldxf></HelloWorldxf> -->
        <HelloWorldqyzt id="dqyzt1"></HelloWorldqyzt>
        <HelloWorldqyztcopy2 id="dqyzt2" style="display:none"></HelloWorldqyztcopy2>
      </div>

      <div class="centerll" v-if="select == 4" style="">
        <HelloWorldxf></HelloWorldxf>
      </div>
    
  </div>
</template>

<script>
// import Handleindex from "@/components/Handleindex.vue";
import HelloWorldxf from "@/components/HelloWorldxf.vue";
// import HelloWorldxfsp from "@/components/HelloWorldxfsp.vue";
import HelloWorldyzt from "@/components/index/HelloWorldyzt.vue";
import HelloWorldqyzt from "@/components/index/HelloWorldqyzt.vue";
import HelloWorldqyztcopy2 from "@/components/index/HelloWorldqyztcopy2.vue";



import HelloWorldzhjg from "@/components/index/HelloWorldzhjg.vue";


export default {
  name: "HomeView",

  components: {
    // handleindex
    HelloWorldxf,
    HelloWorldyzt,
    HelloWorldqyzt,
    HelloWorldqyztcopy2,
    HelloWorldzhjg
  },
  data() {
    return {
      select: 1,
      nowtime:"",
      nowtimel:"",
      timer: null,    
      nowWeek: '',    
      nowDate: '',    
      nowTime: '' ,
      wendu:'',
      tianqi:'',
      tupian:'',
    };
  },

  methods: {
    overhhh(){
      var _this=this
      
          setTimeout(function (){
            console.log("111111111")
            _this.handlelildqyzt2()
          },5000)
    },

  


    handlelildqyzt1(){
      console.log(document.getElementById("dqyzt1").style.display="flex")
      console.log(document.getElementById("dqyzt2").style.display="none")
       
      

      

    },
   
    handlelildqyzt2(){
      console.log(document.getElementById("dqyzt1").style.display="none")
      console.log(document.getElementById("dqyzt2").style.display="flex")

    },

    
    handlelil() {

      
      // console.log(document.getElementById("dqyzt2").style.display="none")
      // new Date() new一个data对象，当前日期和时间
      // toLocaleString() 方法可根据本地时间把 Date 对象转换为字符串，并返回结果。
      this.nowtime = new Date().toLocaleString();
      console.log(this.nowtimel=this.nowtime.split(" ")[0].split("/")[0]+"-"+this.nowtime.split(" ")[0].split("/")[1]+"-"+this.nowtime.split(" ")[0].split("/")[2]);


      



    },

    setNowTimes () {  
      //获取当前时间
      let myDate = new Date()  
      let wk = myDate.getDay()  
      let yy = String(myDate.getFullYear())  
      let mm = myDate.getMonth() + 1  
      let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())  
      let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())  
      let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())  
      let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())  
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      let week = weeks[wk]  
      this.nowDate = yy + '-' + mm + '-' + dd  
      this.nowTime = hou + ':' + min + ':' + sec  
      this.nowWeek = week
      console.log(this.nowWeek);
    },
    handle(){


          this.$http.get('https://devapi.qweather.com/v7/weather/now?location=101010100&key=275a8bed49094becbc0f19b090ff7538')
          .then( (res) => {
            console.log(res.data.now)
            this.wendu=res.data.now.temp+"℃"
            this.tianqi=res.data.now.text
            this.tupian=res.data.now.icon
            console.log(this.tupian);
                          
          })

    },



    
  },
  mounted() {
    this.handlelil(),
    this.setNowTimes(),
    this.handle()

    
  },
};
</script>


<style scoped>
@media screen and (min-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background-color: #091a52; */
    background: url(../../public/image/beijing11.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center{
    background: url(../../public/image/biaoti.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;

  }
  .center_left{
    /* background-color: #094d00; */
    width: 34%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .center_on{
    /* background-color: aliceblue; */
    color: #ffffff;
    font-size: 20px;
    width: 42%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

  }
  .center_right{
    /* background-color: black; */
    color: #ffffff;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;


  }
  .centerll {
    width: 95%;
    /* background-color: aqua; */
    margin-top: 5px;
    height: 88%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .active{
    background: url(../../public/image/xuanze.png);
    background-repeat: no-repeat;
    background-size: 100% 135%;
    background-position-x: 44%;

  }
  
}

/* 小于1400px */
@media screen and (max-width: 1600px) {
  * {
    margin: 0;
    padding: 0;
  }
  .home {
    width: 100%;
    height: 100%;
    /* background-color: #02004d; */
    background: url(../../public/image/beijing11.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center{
     background: url(../../public/image/biaoti.png);
     background-repeat: no-repeat;
     background-size: 100%;
     width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;

  }
  .center_left{
    /* background-color: #094d00; */
    width: 34%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .center_on{
    /* background-color: aliceblue; */
    color: #ffffff;
    font-size: 20px;
    width: 42%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

  }
  .center_right{
    /* background-color: black; */
    color: #ffffff;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;


  }
  .centerll {
    width: 95%;
    /* background-color: aqua; */
    margin-top: 1%;
    height: 88%;
    /* padding: 1%; */
    display: flex;
    justify-content: space-between;
  }
  .active{
    background: url(../../public/image/xuanze.png);
    background-repeat: no-repeat;
    background-size: 100% 135%;
    background-position-x: 44%;

  }
  
}
</style>









